import React, {useState} from 'react'
import {useParams} from 'react-router-dom'
import {useWorkspace} from '../../../hooks/workspaces'
import { CenteredError } from '../../../styles/shared'
import Loading from '../../../components/loading/Loading'
import HeaderComponent from '../../../components/header/Header'
import ContentComponent from '../../../components/content/Content'
import CountingSetupList from '../../../components/countingsetup/CountingSetupList/CountingSetupList'
import SpacesList from '../../../components/spaces/SpacesList/SpacesList'
import {isUndefined} from '../../../helpers/helpers'
import MapInfoView from "../../../components/map/MapInfoView/MapInfoView";


interface Params {
    id: string
}

const ClientsViewPage = () => {
    const { id } = useParams<Params>()
    const { data, error, isLoading } = useWorkspace(id)
    const [blinking, setBlinking] = useState<string|null>(null);

    if (isLoading) return <CenteredError><Loading/></CenteredError>
    if (error) return <CenteredError>Error occurred while fetching: {error.message}</CenteredError>
    if (data == null || isUndefined(data?.id)) return <CenteredError>Data is undefined</CenteredError>

    return(
        <>
            <HeaderComponent title={data.name} subtitle={data.description}>
                {/*<ActionLink to={`/clients/edit/${data.id}`} icon={<Pencil/>}>*/}
                {/*    Edit*/}
                {/*</ActionLink>*/}
            </HeaderComponent>
            <ContentComponent>
                <div className={"mb-4 h-96"}>
                    <MapInfoView ids={data.spaces} setBlinking={setBlinking} />
                </div>
                <div className={"flex mb-4 flex-wrap"}>
                    <div className={"w-full pr-2"}>
                        <SpacesList ids={data.spaces}/>
                    </div>
                    <div className={"w-full pl-2"}>
                        <CountingSetupList spaces={data.spaces} mapPins={true} blinking={blinking}/>
                    </div>
                </div>
            </ContentComponent>
        </>
    )
}

export default ClientsViewPage
