import React from 'react'
import styled from 'styled-components'

interface Props {
    padding?: string
    className?: string
}

const ContentComponent = ({ children, padding="px-4", className }: React.PropsWithChildren<Props>) => (
    <Container>
        <Content className={`${padding} ${className ?? ''}`}>
            {children}
        </Content>
    </Container>
)

const Container = styled.div.attrs({
    className: ""
})`
  width: 100%;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
`

const Content = styled.div`
  width: 100%;
  flex: 1;
`

export default ContentComponent
