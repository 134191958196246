import React from 'react'
import styled from 'styled-components'
import {SubHeader, Table, Tbody, Td, Th, Thead, EditTh, ActionTd} from '../../../styles/shared'
import generateID from '../../../helpers/id'
import dayjs from 'dayjs'
import ShadowTable from '../../tables/ShadowTable/ShadowTable'
import {GetNodeAdmin} from '../../../models/node'
import { Times } from '@styled-icons/fa-solid'
import {useMutation} from 'react-query'
import {deleteNodeAdmin} from '../../../hooks/nodeadmins'
import {AxiosResponse} from 'axios'

interface Props {
    node_admins: GetNodeAdmin[]
    title?: string
    deleteCallback?: () => void
}

const NodeAdminList = ({ node_admins, title='Node Admins', deleteCallback }: Props) => {
    const mutation = useMutation<AxiosResponse<boolean>, Error, string>(deleteNodeAdmin, {})

    const onDelete = (id: string) => {
        if (window.confirm("Are you sure you want to delete this node admin?")) {
            mutation.mutateAsync(id)
                .then(() => {
                    if (deleteCallback) deleteCallback()
                })
                .catch(() => {
                    if (deleteCallback) deleteCallback()
                })
        }
    }

    return(
        <Wrapper>
            <div className={"mb-3"}>
                <SubHeader>{title}</SubHeader>
            </div>
            <ShadowTable>
                <Table>
                    <Thead>
                        <tr>
                            <Th scope={"col"}>Node Uid</Th>
                            <Th scope={"col"}>Start Date</Th>
                            <Th scope={"col"}>Created</Th>
                            <Th scope={"col"}>Updated</Th>
                            <Th scope={"col"}>End Date</Th>
                            <EditTh scope={"col"} className={"relative px-6 py-3"}>
                                <span className={"sr-only"}>View</span>
                            </EditTh>
                        </tr>
                    </Thead>
                    <Tbody>
                        {
                            node_admins.map((value, index) => (
                                <tr key={generateID({page: 'node-node-admins-item', slug: value.node_admin.id, index: index})}>
                                    <Td>{value.node.uid}</Td>
                                    <Td>{value.node_admin.start_date === null ? "" : dayjs(value.node_admin.start_date).format('DD-MM-YYYY')}</Td>
                                    <Td>{dayjs(value.node_admin.created_at).fromNow()}</Td>
                                    <Td>{dayjs(value.node_admin.updated_at).fromNow()}</Td>
                                    <Td>{value.node_admin.end_date === null ? "" : dayjs(value.node_admin.end_date).fromNow()}</Td>
                                    <ActionTd>
                                        <ActionButton type={"button"} onClick={() => onDelete(value.node_admin.id)}>
                                            <Times />
                                        </ActionButton>
                                    </ActionTd>
                                </tr>
                            ))
                        }
                    </Tbody>
                </Table>
            </ShadowTable>
        </Wrapper>
    )
}

const Wrapper = styled.div.attrs({
    className: "flex flex-col mt-3"
})``

const ActionButton = styled.button`
  border: none;
  background-color: transparent;
  
  & > svg {
    width: 15px;
    height: auto;
  }
`

export default NodeAdminList
