import React, {PropsWithChildren} from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import { HomeAlt } from '@styled-icons/boxicons-regular'

const InstallerLayout = ({ children }: PropsWithChildren<any>) => (
    <Container>
        <Main>
            {children}
        </Main>
        <BackHome to={`/installer`}>
            <HomeAlt/>
        </BackHome>
    </Container>
)

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  background-color: white;
  flex-direction: column;
`

const Main = styled.div.attrs({
    className: ""
})`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const BackHome = styled(Link)`
  width: 75px;
  height: 75px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  border-radius: 999999px;
  background-color: #1482ff;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 20px;
  transition: 0.2s background-color;
  display: flex;
  align-items: center;
  justify-content: center;
  
  & > svg {
    width: 30px;
    height: auto;
  }
  
  &:hover {
    background-color: #07f;
  }
`

export default InstallerLayout
