import {MultiLineField, MultiPointField, MultiPolygonField} from './location'

export interface Base {
    id: string
    created_at: Date
    updated_at: Date
}

export interface SpaceField {
    space: string
}

export interface GatewayField {
    gateway: string
}

export interface GatewayNilField {
    gateway: string|null
}

export interface Vertex extends Base {
    id: string
    display_name: string|null
    description: string|null
    notes: string[]
    location: MultiPointField|null
    location_area: MultiPolygonField|null
    location_line: MultiLineField|null
    status: string|null
}

export enum CLSType {
    Counter="Counter",
    ParkingBay="ParkingBay",
    Gateway="Gateway"
}

export interface GenericLazyReferenceField {
    cls: CLSType
    id: string
}

export interface ReferenceField {
    _cls: CLSType
    _ref: {
        $ref: string
        $id: string
    }
}
