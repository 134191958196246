import React, {useMemo, useRef, useState} from 'react';
import env from '../../../production.env'
import Map, {MapLayerMouseEvent, MapRef, Marker} from "react-map-gl";
import styled from "styled-components";
import 'mapbox-gl/dist/mapbox-gl.css';
import axios from "axios";
import {useQuery} from "react-query";
import {Node} from "../../../models/node";
import {LngLat} from "mapbox-gl";

interface Props {
    ids: string[]
    setBlinking: any
}

interface Result {
    count: number
    items: Node[]
}

export default function MapInfoView({ ids, setBlinking }: Props) {
    const request = (spaces?: string[]) => {
        return axios.get(`/api/v1/countingsetups/?_start=0&_end=99999&_sort=display_name&_order=ASC${(spaces != null && spaces?.length > 0) ? `&_spaces=${spaces.join(',')}` : ''}`).then(res => res.data).catch(err => err.response.data)
    }

    const { data } = useQuery<Result, Error>(['countingsetups', 0, ''], () => request(ids), { keepPreviousData: true })

    const ref = useRef<MapRef>(null)

    const [state, setState] = useState<any>({
        latitude: data && data.items && data.items.length && data.items[0].location && data.items[0].location.coordinates ? data.items[0].location.coordinates[0][0] : 52.35026259227887,
        longitude:  data && data.items && data.items.length && data.items[0].location && data.items[0].location.coordinates ? data.items[0].location.coordinates[0][1] : 4.762992649377801,
        zoom: 10,
        width: "100%",
        height: "100%"
    })

    const [coordinates, setCoordinates] = useState<LngLat>(new LngLat(52.35026259227887, 4.762992649377801));
    const defaultMarkerName = 'click on a marker'
    const [markerName, setMarkerName] = useState<string|null>(defaultMarkerName);
    const [activeMarker, setActiveMarker] = useState<number[][]|null>(null);

    const markers = useMemo(() => data?.items?.map( ({ id, location, display_name }) => (
            location && <Marker
                longitude={location.coordinates[0][1]}
                latitude={location.coordinates[0][0]}
                key={Math.random()}
                anchor="bottom"
                onClick={() => {
                    setBlinking(id)
                    setMarkerName(display_name)
                    setActiveMarker(location.coordinates)
                }}
            >
                {/* Can have the following classes: countingSetupMarker active activeCountingSetup */}
                <div className={'countingSetupMarker ' + (location.coordinates === activeMarker ? 'active' : '')}>
                    <span>{display_name}</span>
                </div>
            </Marker>
        )
    ), [data, setBlinking, activeMarker]);

    return (
        <Map
            reuseMaps
            id="infoMap"
            className="h-96"
            ref={ref}
            mapboxAccessToken={env.mapBoxToken}
            mapStyle={'mapbox://styles/mapbox/light-v10'}
            {...state}
            onMove={(evt: { viewState: any }) => setState(evt.viewState)}
            onMouseMove={(evt: MapLayerMouseEvent) => {
                setCoordinates(evt.lngLat)
            }}
        >
            <HoverWrapper>
                Marker: <b>{markerName === defaultMarkerName || !markerName ? (<i>{defaultMarkerName}</i>) : markerName}</b><br />
                lng: {coordinates.lng}, lat: {coordinates.lat}
            </HoverWrapper>
            { markers }
        </Map>
    );
}

const HoverWrapper = styled.div.attrs({
    className: "absolute shadow overflow-hidden border-b border-gray-200 bg-white m-3 py-2 px-4 font-medium"
})``

