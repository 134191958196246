import React from 'react'
import {FormikProps, withFormik} from 'formik'
import * as Yup from 'yup'
import generateID from '../../../helpers/id'
import {PlusCircle, SelectMultiple} from '@styled-icons/boxicons-regular'
import {UseMutationResult} from 'react-query'
import {Space} from '../../../models/space'
import { History } from 'history'
import {Cross} from '@styled-icons/entypo'
import {
    ErrorWrapper, ErrorItem,
    FormButton, FormGroup, FormInput, FormLabel, FormTextarea, FormTextAreaWrapper, FormInline, FormInlineColl
} from '../../../styles/shared'
import {CreateSpaceInput} from '../../../hooks/spaces'
import {
    workspaceSelector,
    WorkspaceSelectorDialog
} from '../../../components/modals/WorkspaceSelector/WorkspaceSelector'
import {selectLocation, SelectLocationDialog} from '../../../components/modals/SelectLocation/SelectLocation'
import {MapMarkerAlt} from '@styled-icons/fa-solid'
import {AxiosResponse} from 'axios'

interface FormValues {
    name: string
    description: string
    notes: string[]
    workspace: string
    location: {
        latitude: number|null
        longitude: number|null
    }
}

interface FormProps {
    mutation: UseMutationResult<AxiosResponse<Space>, Error, CreateSpaceInput, unknown>
    history: History
    initialName?: string
    initialDescription?: string
    initialNotes?: string[]
    initialWorkspace?: string
}

const innerForm: React.FC<FormProps & FormikProps<FormValues>> = (props) => {
    const addNote = () => {
        props.setValues(oldValues => ({
            ...oldValues,
            notes: [...oldValues.notes, ""]
        }))
    }

    const removeNote = (index: number) => {
        props.setValues(oldValues => ({
            ...oldValues,
            notes: oldValues.notes.filter((value, index1) => index1 !== index)
        }))
    }

    const onWorkspaceClick = async () => {
        await workspaceSelector(WorkspaceSelectorDialog, {})
            .then(res => {
                if (res !== null) props.setFieldValue(`workspace`, res)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const clickSelectLocation = async () => {
        await selectLocation(SelectLocationDialog, {location: props.values.location.latitude != null && props.values.location.longitude != null ? {latitude: props.values.location.latitude, longitude: props.values.location.longitude} : undefined})
            .then(res => {
                if (res !== null) {
                    props.setFieldValue('location.latitude', res.location.latitude)
                    props.setFieldValue('location.longitude', res.location.longitude)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    return(
        <form className={"mt-5"} onSubmit={props.handleSubmit}>
            {
                props.status != null
                &&
                <div className={"shadow-none my-3 p-2 bg-green-300 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"} role="alert">
                    <span className={"flex rounded-full bg-green-600 text-white uppercase px-2 py-1 text-xs font-bold mr-3"}>Status</span>
                    <span className={"font-semibold text-white mr-2 text-left flex-auto"}>{props.status}</span>
                </div>
            }
            <FormGroup>
                <FormLabel>
                    Location
                    <MapMarkerAlt onClick={clickSelectLocation}/>
                </FormLabel>
                <FormInline>
                    <FormInlineColl>
                        <FormInput onChange={props.handleChange} onBlur={props.handleBlur} step={"any"} type={"number"} name={"location.latitude"} id={"location.latitude"} placeholder={"Latitude"} value={props.values.location.latitude ?? ""}/>
                        <ErrorWrapper>
                            {
                                props.errors.location?.latitude && props.touched.location?.latitude && <ErrorItem>{props.errors.location?.latitude}</ErrorItem>
                            }
                        </ErrorWrapper>
                    </FormInlineColl>
                    <FormInlineColl>
                        <FormInput onChange={props.handleChange} onBlur={props.handleBlur} step={"any"} type={"number"} name={"location.longitude"} id={"location.longitude"} placeholder={"Longitude"} value={props.values.location.longitude ?? ""}/>
                        <ErrorWrapper>
                            {
                                props.errors.location?.longitude && props.touched.location?.longitude && <ErrorItem>{props.errors.location?.longitude}</ErrorItem>
                            }
                        </ErrorWrapper>
                    </FormInlineColl>
                </FormInline>
            </FormGroup>
            <FormGroup>
                <FormLabel htmlFor={"name"}>Name</FormLabel>
                <FormInput onChange={props.handleChange} onBlur={props.handleBlur} type={"string"} name={"name"} id={"name"} value={props.values.name}/>
                <ErrorWrapper>
                    {
                        props.errors.name && props.touched.name && <ErrorItem>{props.errors.name}</ErrorItem>
                    }
                </ErrorWrapper>
            </FormGroup>
            <FormGroup>
                <FormLabel htmlFor={"description"}>Description</FormLabel>
                <FormTextarea onChange={props.handleChange} onBlur={props.handleBlur} name={"description"} id={"description"} value={props.values.description}/>
                <ErrorWrapper>
                    {
                        props.errors.description && props.touched.description && <ErrorItem>{props.errors.description}</ErrorItem>
                    }
                </ErrorWrapper>
            </FormGroup>
            <FormGroup>
                <FormLabel htmlFor={"notes"}>
                    Notes
                    <PlusCircle onClick={addNote}/>
                </FormLabel>
                {
                    props.values.notes.map((value, index) => (
                        <FormTextAreaWrapper key={generateID({page: 'clients-new-page-form', slug: 'notes', index: index})}>
                            <FormTextarea onChange={props.handleChange} onBlur={props.handleBlur} name={`notes[${index}]`} id={"notes"} value={value}/>
                            <Cross onClick={() => removeNote(index)}/>
                        </FormTextAreaWrapper>
                    ))
                }
                <ErrorWrapper>
                    {
                        props.errors.description && props.touched.description && <ErrorItem>{props.errors.description}</ErrorItem>
                    }
                </ErrorWrapper>
            </FormGroup>
            <FormGroup>
                <FormInline>
                    <FormInlineColl>
                        <FormLabel>
                            Workspace:
                            <SelectMultiple onClick={() => onWorkspaceClick()}/>
                        </FormLabel>
                        <ErrorWrapper>
                            {
                                props.errors.workspace && props.touched.workspace && <ErrorItem>{props.errors.workspace}</ErrorItem>
                            }
                        </ErrorWrapper>
                    </FormInlineColl>
                    <FormInlineColl>
                        <FormInput onChange={props.handleChange} onBlur={props.handleBlur} type={"string"} name={`workspace`} id={`workspace`} placeholder={"Workspace ID"} value={props.values.workspace ?? ""}/>
                    </FormInlineColl>
                </FormInline>
            </FormGroup>
            <FormButton type={"submit"} disabled={
                props.isSubmitting ||
                !!(props.errors.name && props.touched.name)
            }>
                Submit
            </FormButton>
        </form>
    );
}



const SpacesNewPageForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: props => ({
        location: {
            latitude: null,
            longitude: null
        },
        name: props.initialName ?? "",
        description: props.initialDescription ?? "",
        notes: props.initialNotes ?? new Array<string>(),
        workspace: props.initialWorkspace ?? ""
    }),
    validationSchema: Yup.object().shape({
        location: Yup.object().shape({
            latitude: Yup
                .mixed()
                .required('Latitude is required')
                .test('validateCoordinate', 'Latitude must be a coordinate', value => new RegExp('^(\\+|-)?(?:90(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]+)?))$').test(value)),
            longitude: Yup
                .mixed()
                .required('Longitude is required')
                .test('validateCoordinate', 'Longitude must be a coordinate', value => new RegExp('^(\\+|-)?(?:180(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]+)?))$').test(value))
        }),
        name: Yup.string()
            .required("Name is required"),
        description: Yup.string()
            .notRequired(),
        notes: Yup.array(),
        workspace: Yup.string()
            .required("Workspace is required")
    }),
    handleSubmit: (values, {props, setSubmitting, setStatus}) => {
        setStatus(undefined)
        props.mutation.mutateAsync({
            name: values.name,
            description: values.description,
            notes: values.notes,
            workspace_id: values.workspace,
            location: {
                lat: values.location.latitude ?? 0,
                lon: values.location.longitude ?? 0,
            }
        }).then(res => {
            setSubmitting(false)
            setStatus("Successfully created the space")
            setTimeout(() => {
                props.history.push(`/spaces/view/${res.data.id}`)
            }, 2000)
        }).catch(err => {
            setSubmitting(false)
            setStatus(err.error ?? "Error while trying to create the client")
        })
    }
})(innerForm)

export default SpacesNewPageForm
