import React from 'react'
import HeaderComponent from '../../../components/header/Header'

const HomePage = () => {
    return (
        <>
            <HeaderComponent title={"Home"}/>
        </>
    )
}

export default HomePage
