import React from 'react'
import HeaderComponent from '../../../../components/header/Header'
import ContentComponent from '../../../../components/content/Content'
import {useParams} from 'react-router-dom'
import {useWorkspace} from '../../../../hooks/workspaces'
import {CenteredError} from '../../../../styles/shared'
import Loading from '../../../../components/loading/Loading'
import CountingSetupList from '../../../../components/countingsetup/CountingSetupList/CountingSetupList'

interface Params {
    id: string
}

const InstallerClientsViewPage = () => {
    const { id } = useParams<Params>()

    const { data, error, isLoading } = useWorkspace(id, `/api/v1/installer/clients/`)

    if (isLoading) return <CenteredError><Loading/></CenteredError>
    if (error) return <CenteredError>Error occurred while fetching: {error.message}</CenteredError>
    if (data == null) return <CenteredError>Data is undefined</CenteredError>

    return (
        <>
            <HeaderComponent title={data.name} subtitle={data.description}/>
            <ContentComponent>
                <CountingSetupList spaces={data.spaces} viewPrefix={`/installer/countingsetups/view`} installPrefix={`/installer/countingsetups/install`} editInstallPrefix={`/installer/countingsetups/edit-install`} requestUrl={`/api/v1/installer/countingsetups/`}/>
            </ContentComponent>
        </>
    )
}

export default InstallerClientsViewPage
