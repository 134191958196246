import React from 'react'
import styled, {keyframes} from 'styled-components'

const Loader = () => {
    return (
        <Container>
            <svg width={"16px"} height={"12px"}>
                <polyline id={"back"} points={"1 6 4 6 6 11 10 1 12 6 15 6"}/>
                <polyline id={"front"} points={"1 6 4 6 6 11 10 1 12 6 15 6"}/>
            </svg>
        </Container>
    )
}

const dash = keyframes`
  62.5% {
    opacity: 0;
  }
  to {
    stroke-dashoffset: 0;
  }
`

const Container = styled.div`
  transform: translate(-50%, -50%) scale(2);

  & > svg > polyline {
    fill: none;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;

    &#back {
      stroke: rgba(black, .3);
    }

    &#front {
      stroke: black;
      stroke-dasharray: 12, 36;
      stroke-dashoffset: 48;
      animation: ${dash} 1s linear infinite;
    }
  }
`

export default Loader
