import React, { useEffect, useRef } from 'react'
import {ToastRawItem} from './toastmanager'
import { Times } from '@styled-icons/fa-solid'
import styled from 'styled-components'

// TODO: Fix the react unmount error memory leak

interface Props {
    item: ToastRawItem
    remove: () => void
}

function Toast({item, remove}: Props) {
    const removeRef = useRef(remove);

    useEffect(() => {
        const duration = item.duration || 3000;
        const id = setTimeout(() => removeRef.current(), duration);

        return () => clearTimeout(id);
    }, [item.duration])

    return(
        // <ToastItemStyle color={item.type === "INFO" ? "green" : "red"}>
        //     {item.content}
        // </ToastItemStyle>
        <div className={`shadow-none mb-2 p-2 items-center ${item.type === "SUCCESS" ? 'bg-green-300 text-indigo-100' : item.type === "ERROR" ? 'bg-red-500 text-indigo-100' : 'bg-gray-300 text-indigo-100'} leading-none lg:rounded-full flex lg:inline-flex`} role="alert">
            <span className={`flex rounded-full ${item.type === "SUCCESS" ? 'bg-green-600' : item.type === "ERROR" ? 'bg-red-700' : 'bg-gray-600'} text-white uppercase px-2 py-1 text-xs font-bold mr-3`}>{item.type}</span>
            <span className={`font-semibold text-white mr-2 text-left flex-auto`}>{item.content}</span>
            <ActionButton onClick={() => remove()}>
                <Times/>
            </ActionButton>
        </div>
    );
}

const ActionButton = styled.button.attrs({
    className: `font-semibold text-white mr-2 text-left flex-auto`
})`
  border: none;
  background-color: transparent;
  
  & > svg {
    width: 12px;
    height: auto;
  }
`

export default Toast
