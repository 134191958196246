import React from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {patchCountingSetup, useCountingSetup} from '../../../hooks/countingsetups'
import {CenteredError} from '../../../styles/shared'
import Loading from '../../../components/loading/Loading'
import HeaderComponent from '../../../components/header/Header'
import {useMutation} from 'react-query'
import {GetCountingSetup} from '../../../models/groups'
import ContentComponent from '../../../components/content/Content'
import CountingSetupsEditPageForm from './CountingSetupsEditPageForm'
import {isUndefined} from '../../../helpers/helpers'
import {AxiosResponse} from 'axios'

interface Params {
    id: string
}

const CountingSetupsEditPage = () => {
    const { id } = useParams<Params>()

    const { data, error, isLoading } = useCountingSetup(id)

    const mutation = useMutation<AxiosResponse<GetCountingSetup>, Error, object>((pData) => patchCountingSetup(id, pData), {})
    const history = useHistory()

    if (isLoading) return <CenteredError><Loading/></CenteredError>
    if (error) return <CenteredError>Error occurred while fetching: {error.message}</CenteredError>
    if (data == null || isUndefined(data?.id)) return <CenteredError>Data is undefined</CenteredError>

    return (
        <>
            <HeaderComponent title={"Edit CountingSetup"}/>
            <ContentComponent>
                <CountingSetupsEditPageForm mutation={mutation} history={history} countingsetup={data}/>
            </ContentComponent>
        </>
    )
}

export default CountingSetupsEditPage
