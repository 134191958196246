import React from 'react'
import styled from 'styled-components'
import {SubHeader, FormInput, ErrorItem, ErrorWrapper, IconDivWrapper} from '../../../styles/shared'
import generateID from '../../../helpers/id'
import {QueryObserverResult, RefetchOptions, RefetchQueryFilters, UseMutationResult} from 'react-query'
import {FormikProps, withFormik} from 'formik'
import * as Yup from 'yup'
import {Cross} from '@styled-icons/entypo'

interface FormValues {
    note: string
}

interface FormProps {
    mutation: UseMutationResult<any, Error, Object, unknown>
    refetch: (options?: ((RefetchOptions & RefetchQueryFilters<any>) | undefined)) => Promise<QueryObserverResult<any, Error>>
    notes: string[]
}

const innerForm: React.FC<FormProps & FormikProps<FormValues>> = (props) => {
    const removeNote = (index: number) => {
        const newNotes = props.notes.slice()
        newNotes.splice(index, 1)
        props.mutation.mutateAsync({
            notes: props.notes.filter((_, index1) => index1 !== index)
        }).then(async () => {
            await props.refetch()
            props.setStatus("Successfully removed the note")
            setTimeout(() => {
                props.setStatus(undefined)
            }, 2000)
        }).catch(async err => {
            await props.refetch()
            props.setStatus(err.error ?? "Error while trying to remove the note")
        })
    }

    return(
        <Wrapper>
            <div className={"mb-3"}>
                <SubHeader>Notes</SubHeader>
            </div>
            {
                props.status != null
                &&
                <div className={"shadow-none my-3 p-2 bg-green-300 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"} role="alert">
                    <span className={"flex rounded-full bg-green-600 text-white uppercase px-2 py-1 text-xs font-bold mr-3"}>Status</span>
                    <span className={"font-semibold text-white mr-2 text-left flex-auto"}>{props.status}</span>
                </div>
            }
            <div className={"bg-white rounded-lg shadow lg:w-1/2"}>
                <ul className={"divide-y divide-gray-100"}>
                    {
                        props.notes.map((value, index) => (
                            <li className={"flex justify-between items-center p-3"} key={generateID({page: 'counting-setup-list-notes-item', slug: value, index: index})}>
                                <IconDivWrapper>
                                    {value}
                                    <Cross onClick={() => removeNote(index)}/>
                                </IconDivWrapper>
                            </li>
                        ))
                    }
                    <li className={"flex justify-between items-center p-3"}>
                        <div className={"mr-2 w-full"}>
                            <form onSubmit={props.handleSubmit}>
                                <FormInput name={"note"} id={"note"} placeholder={"New note"} onChange={props.handleChange} onBlur={props.handleBlur} type={"text"} value={props.values.note}/>
                                <ErrorWrapper>
                                    {
                                        props.errors.note && props.touched.note && <ErrorItem>{props.errors.note}</ErrorItem>
                                    }
                                </ErrorWrapper>
                            </form>
                        </div>
                        <svg onClick={() => props.submitForm()} xmlns={"http://www.w3.org/2000/svg"} className={"w-6 h-6 cursor-pointer"} fill={"none"} viewBox={"0 0 24 24"} stroke={"currentColor"}>
                            <path strokeLinecap={"round"} strokeLinejoin={"round"} strokeWidth={"2"} d={"M17 8l4 4m0 0l-4 4m4-4H3"}/>
                        </svg>
                    </li>
                </ul>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div.attrs({
    className: "flex flex-col mt-3"
})``

const NotesList = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => ({
        note: ""
    }),
    validationSchema: Yup.object().shape({
        note: Yup.string()
            .required("Note is required"),
    }),
    handleSubmit: (values, {props, setSubmitting, setStatus, resetForm}) => {
        setStatus(undefined)
        props.mutation.mutateAsync({
            notes: [...props.notes, values.note]
        }).then(async () => {
            resetForm({})
            await props.refetch()
            setSubmitting(false)
            setStatus("Successfully updated the notes")
            setTimeout(() => {
                setStatus(undefined)
            }, 2000)
        }).catch(async err => {
            resetForm({})
            await props.refetch()
            setSubmitting(false)
            setStatus(err.error ?? "Error while trying to update the notes")
        })
    }
})(innerForm)

export default NotesList
