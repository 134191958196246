import {GetNodeAdmin} from '../models/node'
import axios, {AxiosPromise} from 'axios'
import {GenericLazyReferenceField} from '../models/base'

export interface PatchPutNodeAdminInput {
    id: string
    data: object
}

export function patchNodeAdmin(id: string, data: object): AxiosPromise<GetNodeAdmin> {
    return axios.patch(`/api/v1/nodeadmins/${id}`, data)
}

export function putNodeAdmin(id: string, data: object): AxiosPromise<GetNodeAdmin> {
    return axios.put(`/api/v1/nodeadmins/${id}`, data)
}

export function deleteNodeAdmin(id: string): AxiosPromise<boolean> {
    return axios.delete(`/api/v1/nodeadmins/${id}`)
}

export interface NewNodeAdminInput {
    node_uid: number
    space: string
    product: GenericLazyReferenceField
    position: number[]
    orientation: number[]
}

export function newNodeAdmin(data: NewNodeAdminInput): AxiosPromise<GetNodeAdmin> {
    return axios.post(`/api/v1/nodeadmins/`, data)
}

export function patchInstallerNodeAdmin(id: string, data: object): AxiosPromise<GetNodeAdmin> {
    return axios.patch(`/api/v1/installer/nodeadmins/${id}`, data)
}

export function putInstallerNodeAdmin(id: string, data: object): AxiosPromise<GetNodeAdmin> {
    return axios.put(`/api/v1/installer/nodeadmins/${id}`, data)
}

export function deleteInstallerNodeAdmin(id: string): AxiosPromise<boolean> {
    return axios.delete(`/api/v1/installer/nodeadmins/${id}`)
}

export function newInstallerNodeAdmin(data: NewNodeAdminInput): AxiosPromise<GetNodeAdmin> {
    return axios.post(`/api/v1/installer/nodeadmins/`, data)
}
