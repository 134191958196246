import React from 'react'
import ActionLink from '../../../components/buttons/action/ActionLink'
import {AddToQueue} from '@styled-icons/boxicons-regular'
import HeaderComponent from '../../../components/header/Header'
import ContentComponent from '../../../components/content/Content'
import NodesList from '../../../components/nodes/NodesList/NodesList'
import SearchList from '../../../components/search/SearchList'

const NodesPage = () => {
    const [name, setName] = React.useState<string>('')

    return (
        <>
            <HeaderComponent title={"Nodes"} subtitleComponent={<SearchList value={name} action={setName}/>}>
                <ActionLink to={`/nodes/new`} icon={<AddToQueue/>}>
                    New
                </ActionLink>
            </HeaderComponent>
            <ContentComponent>
                <NodesList name={name}/>
            </ContentComponent>
        </>
    )
}

export default NodesPage
