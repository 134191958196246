import React, {useEffect} from 'react'
import styled from 'styled-components'
import generateID from '../../../helpers/id'
import ShadowTable from '../../tables/ShadowTable/ShadowTable'
import { CenteredError, SubHeader, Table, Thead, Th, EditTh, Tbody, Td, ActionTd } from '../../../styles/shared'
import {Link, useHistory} from 'react-router-dom'
import dayjs from 'dayjs'
import Loading from '../../loading/Loading'
import {MultiPointField} from '../../../models/location'
import {viewLocation, ViewLocationDialog} from '../../modals/ViewLocation/ViewLocation'
import {CountingSetup} from '../../../models/groups'
import axios from 'axios'
import {useQuery} from 'react-query'
import Pagination from '../../buttons/pagination/Pagination'
import {AddToQueue} from "@styled-icons/boxicons-regular";
import ActionLink from "../../buttons/action/ActionLink";
import ActionLinkButton from "../../buttons/action/ActionLinkButton";
import {spaceSelector, SpaceSelectorDialog} from "../../modals/SpaceSelector/SpaceSelector";
import {MapPin} from "@styled-icons/fa-solid";
import {useMap} from "react-map-gl";

interface Props {
    spaces?: string[]
    title?: boolean
    installPrefix?: string
    editInstallPrefix?: string
    viewPrefix?: string
    requestUrl?: string
    name?: string
    mapPins?: boolean
    blinking?: string|null
}

interface Result {
    count: number
    items: CountingSetup[]
}

const CountingSetupList = ({ spaces, title=true, mapPins=false, blinking=null, installPrefix='/countingsetups/install', editInstallPrefix='/countingsetups/edit-install', name = '', viewPrefix='/countingsetups/view', requestUrl='/api/v1/countingsetups/' }: Props) => {
    const [page, setPage] = React.useState<number>(0)
    const history = useHistory()
    const {infoMap} = useMap()

    const request = (page: number, name: string, spaces?: string[]) => {
        return axios.get(`${requestUrl}?_start=${page === 0 ? 0 : page*25}&_end=${(page+1)*25}&_sort=display_name&_order=ASC&_name=${name}${(spaces != null && spaces?.length > 0) ? `&_spaces=${spaces.join(',')}` : ''}`).then(res => res.data).catch(err => err.response.data)
    }

    const { data, error, isLoading, isPreviousData, isFetching } = useQuery<Result, Error>(['countingsetups', page, name], () => request(page, name, spaces), { keepPreviousData: true })

    useEffect(() => {
        setPage(0)
    }, [name])

    if (isLoading || isFetching) return <CenteredError><Loading/></CenteredError>
    if (error) return <CenteredError>Error occurred while fetching: {error.message}</CenteredError>
    if (data == null || data?.items == null) return <CenteredError>Data is undefined</CenteredError>
    if (data.items.length === 0) return <CenteredError>No CountingSetups found</CenteredError>

    const showLocation = async (location: MultiPointField) => await viewLocation(ViewLocationDialog, {location: location})

    const onNewClick = async () => {
        await spaceSelector(SpaceSelectorDialog, {})
            .then(res => {
                if (res !== null) history.push(`/countingsetups/spaces/${res}/new`)
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <>
            {
                title
                &&
                <div className={"mb-3 flex"}>
                    <SubHeader>CountingSetups</SubHeader>
                    {spaces?.length === 1 ? (
                        <ActionLink to={`/countingsetups/spaces/${spaces[0]}/new`} icon={<AddToQueue/>}>
                            New
                        </ActionLink>
                    ) : (
                        <ActionLinkButton onClick={onNewClick} icon={<AddToQueue/>}>
                            New
                        </ActionLinkButton>
                    )}
                </div>
            }
            <Wrapper>
                <ShadowTable>
                    <Table>
                        <Thead>
                            <tr>
                                <Th scope={"col"}>Name</Th>
                                <Th scope={"col"}>Road Section</Th>
                                <Th scope={"col"}>Number of lanes</Th>
                                <Th scope={"col"}>Created</Th>
                                <Th scope={"col"}>Updated</Th>
                                {mapPins && (<Th scope={"col"}>Map</Th>)}
                                <EditTh scope={"col"} className={"relative px-6 py-3"}>
                                    <span className={"sr-only"}>View</span>
                                </EditTh>
                            </tr>
                        </Thead>
                        <Tbody>
                            {
                                data.items.map((value, index) => (
                                    <tr key={generateID({page: 'counting-setup-list-item', slug: value.id, index: index})} className={blinking === value.id ? 'blink' : ''}>
                                        <Td>{value.display_name}</Td>
                                        <Td>{value.name_positive} - {value.name_negative}</Td>
                                        <Td>{value.number_of_lanes}</Td>
                                        <Td>{dayjs(value.created_at).fromNow()}</Td>
                                        <Td>{dayjs(value.updated_at).fromNow()}</Td>
                                        {mapPins && (
                                            <Td>{
                                                value.location && value.location.coordinates && value.location.coordinates.length && (
                                                    // @ts-ignore
                                                    <MapPin className='cursor-pointer' style={{width: 20}} onClick={() => infoMap.flyTo({center: [value.location.coordinates[0][1], value.location.coordinates[0][0]]})} />
                                                )
                                            }</Td>
                                        )}
                                        <ActionTd>
                                            {
                                                value.location !== null
                                                &&
                                                <button className={"text-blue-500 font-medium hover:text-blue-700 mr-3"} onClick={() => showLocation(value.location!)}>Location</button>
                                            }
                                            <Link to={`${value.installed ? editInstallPrefix : installPrefix}/${value.id}`} className={"text-blue-500 hover:text-blue-700 mr-3"}>{value.installed ? "Edit install" : "Install"}</Link>
                                            <Link to={`${viewPrefix}/${value.id}`} className={"text-blue-500 hover:text-blue-700"}>View</Link>
                                        </ActionTd>
                                    </tr>
                                ))
                            }
                        </Tbody>
                    </Table>
                </ShadowTable>
                <Pagination count={data.count} page={page} action={setPage} isPreviousData={isPreviousData}/>
            </Wrapper>
        </>
    )
}

const Wrapper = styled.div.attrs({
    className: "flex flex-col mt-3"
})``

export default CountingSetupList
