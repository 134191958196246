import React from 'react'
import {FormikProps, withFormik} from 'formik'
import {GetCountingSetup} from '../../../../models/groups'
import * as Yup from 'yup'
import {
    ErrorItem,
    ErrorWrapper, FormButton,
    FormCancelButton,
    FormGroup,
    FormInline,
    FormInlineColl,
    FormInput,
    FormLabel
} from '../../../../styles/shared'
import styled from 'styled-components'
import generateID from '../../../../helpers/id'
import { SelectMultiple } from '@styled-icons/boxicons-regular'
import {nodeSelector, NodeSelectorDialog} from '../../../../components/modals/NodeSelector/NodeSelector'
import {UseMutationResult} from 'react-query'
import {InstallCountingSetupInput} from '../../../../hooks/countingsetups'
import { History } from 'history'
import {AxiosResponse} from 'axios'
import {withToast, WithToastProps} from '../../../../components/toast/toastmanager'
import {nodeExists} from '../../../../hooks/nodes';

interface FormValues {
    items: FormValuesItem[]
}

interface FormValuesItem {
    counter_id: string
    gateway_id: string|null
    gateway_uid: number|null
    sensor_1_uid: number|null
    distance: number|null
    sensor_2_uid: number|null
}

interface FormProps extends WithToastProps {
    mutation: UseMutationResult<AxiosResponse<GetCountingSetup>, Error, InstallCountingSetupInput[], unknown>
    history: History
    countingSetup: GetCountingSetup
}

const InnerForm: React.FC<FormProps & FormikProps<FormValues> & WithToastProps> = (props) => {
    const onGatewayClick = async (index: number) => {
        await nodeSelector(NodeSelectorDialog, {node_type: 'GATEWAY', requestUrl: `/api/v1/installer/nodes/`})
            .then(res => {
                if (res !== null) props.setFieldValue(`items.${index}.gateway_uid`, res)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const onSensorClick = async (index: number, item: number) => {
        await nodeSelector(NodeSelectorDialog, {node_type: 'SENSOR', requestUrl: `/api/v1/installer/nodes/`})
            .then(res => {
                if (res !== null) props.setFieldValue(`items.${index}.sensor_${item}_uid`, res)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const validateUIDs = async (): Promise<boolean> => {
        let valid = true
        // Set to valid=false if valid is not already false
        for await (const value of props.values.items) {
            if (value.gateway_uid) {
                const _valid = await nodeExists(value.gateway_uid, true)
                if (!_valid) {
                    props.toast.addToast({
                        content: "Gateway uid is invalid",
                        type: "ERROR",
                        duration: 3000
                    })
                    valid = false
                }
            }
            if (value.sensor_1_uid) {
                const _valid = await nodeExists(value.sensor_1_uid, true)
                if (!_valid) {
                    props.toast.addToast({
                        content: "Sensor 1 uid is invalid",
                        type: "ERROR",
                        duration: 3000
                    })
                    valid = false
                }
            } else {
                props.toast.addToast({
                    content: "Sensor 1 uid is invalid",
                    type: "ERROR",
                    duration: 3000
                })
            }
            if (value.sensor_2_uid) {
                const _valid = await nodeExists(value.sensor_2_uid, true)
                if (!_valid) {
                    props.toast.addToast({
                        content: "Sensor 2 uid is invalid",
                        type: "ERROR",
                        duration: 3000
                    })
                    valid = false
                }
            }
        }

        return valid
    }

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const valid = await validateUIDs()
        if (!valid) return;
        props.handleSubmit(e)
    }

    return(
        <form className={"mt-5"} onSubmit={onSubmit}>
            {
                props.status != null
                &&
                <div className={"shadow-none my-3 p-2 bg-green-300 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"} role="alert">
                    <span className={"flex rounded-full bg-green-600 text-white uppercase px-2 py-1 text-xs font-bold mr-3"}>Status</span>
                    <span className={"font-semibold text-white mr-2 text-left flex-auto"}>{props.status}</span>
                </div>
            }
            {
                props.countingSetup.counters.map((value, index) => (
                    <Item key={generateID({page: 'counting-setup-install-item', slug: value.id, index: index})}>
                        <h1>Rijstrook {index+1}<br/>Richting: {value.count_direction === 1 ? props.countingSetup.name_positive : props.countingSetup.name_negative}</h1>
                        <div className={"mt-3"}>
                            <FormGroup>
                                <FormInline>
                                    <FormInlineColl>
                                        <FormLabel>
                                            Gateway node UID:
                                            <SelectMultiple onClick={() => onGatewayClick(index)}/>
                                        </FormLabel>
                                        <ErrorWrapper>
                                            {
                                                // @ts-ignore
                                                props.errors.items != null && props.errors.items.length > index && props.errors.items[index]?.gateway_uid && props.touched.items != null && props.touched.items.length > index && props.touched.items[index]?.gateway_uid && <ErrorItem>{props.errors.items[index]?.gateway_uid}</ErrorItem>
                                            }
                                        </ErrorWrapper>
                                    </FormInlineColl>
                                    <FormInlineColl>
                                        <FormInput onChange={props.handleChange} onBlur={props.handleBlur} type={"number"} name={`items[${index}].gateway_uid`} id={`items.${index}.gateway_uid`} placeholder={"Gateway UID"} value={props.values.items[index]?.gateway_uid ?? ""}/>
                                    </FormInlineColl>
                                </FormInline>
                            </FormGroup>
                            <FormGroup>
                                <FormInline>
                                    <FormInlineColl>
                                        <FormLabel>
                                            Sensor 1 node UID:
                                            <SelectMultiple onClick={() => onSensorClick(index, 1)}/>
                                        </FormLabel>
                                        <ErrorWrapper>
                                            {
                                                // @ts-ignore
                                                props.errors.items != null && props.errors.items.length > index && props.errors.items[index]?.sensor_1_uid && props.touched.items != null && props.touched.items.length > index && props.touched.items[index]?.sensor_1_uid && <ErrorItem>{props.errors.items[index]?.sensor_1_uid}</ErrorItem>
                                            }
                                        </ErrorWrapper>
                                    </FormInlineColl>
                                    <FormInlineColl>
                                        <FormInput onChange={props.handleChange} onBlur={props.handleBlur} type={"number"} name={`items[${index}].sensor_1_uid`} id={`items.${index}.sensor_1_uid`} placeholder={"Sensor 1 UID"} value={props.values.items[index]?.sensor_1_uid ?? ""}/>
                                    </FormInlineColl>
                                </FormInline>
                            </FormGroup>
                            <FormGroup>
                                <FormInline>
                                    <FormInlineColl>
                                        <FormLabel>
                                            Tussen afstand:
                                        </FormLabel>
                                        <ErrorWrapper>
                                            {
                                                // @ts-ignore
                                                props.errors.items != null && props.errors.items.length > index && props.errors.items[index]?.distance && props.touched.items != null && props.touched.items.length > index && props.touched.items[index]?.distance && <ErrorItem>{props.errors.items[index]?.distance}</ErrorItem>
                                            }
                                        </ErrorWrapper>
                                    </FormInlineColl>
                                    <FormInlineColl>
                                        <FormInput onChange={props.handleChange} onBlur={props.handleBlur} type={"number"} name={`items[${index}].distance`} id={`items.${index}.distance`} placeholder={""} value={props.values.items[index]?.distance ?? ""}/>
                                        <p className={"ml-2"}>meter</p>
                                    </FormInlineColl>
                                </FormInline>
                            </FormGroup>
                            <FormGroup>
                                <FormInline>
                                    <FormInlineColl>
                                        <FormLabel>
                                            Sensor 2 node UID:
                                            <SelectMultiple onClick={() => onSensorClick(index, 2)}/>
                                        </FormLabel>
                                        <ErrorWrapper>
                                            {
                                                // @ts-ignore
                                                props.errors.items != null && props.errors.items.length > index && props.errors.items[index]?.sensor_2_uid && props.touched.items != null && props.touched.items.length > index && props.touched.items[index]?.sensor_2_uid && <ErrorItem>{props.errors.items[index]?.sensor_2_uid}</ErrorItem>
                                            }
                                        </ErrorWrapper>
                                    </FormInlineColl>
                                    <FormInlineColl>
                                        <FormInput onChange={props.handleChange} onBlur={props.handleBlur} type={"number"} name={`items[${index}].sensor_2_uid`} id={`items.${index}.sensor_2_uid`} placeholder={"Sensor 2 UID"} value={props.values.items[index]?.sensor_2_uid ?? ""}/>
                                    </FormInlineColl>
                                </FormInline>
                            </FormGroup>
                        </div>
                    </Item>
                ))
            }
            <div className={"flex w-full"}>
                <FormButton type={"submit"} disabled={
                    props.isSubmitting || !props.isValid
                }>
                    Submit
                </FormButton>
                <div className={"ml-2"}>
                    <FormCancelButton type={"button"} onClick={() => props.history.goBack()}>
                        Cancel
                    </FormCancelButton>
                </div>
            </div>
        </form>
    )
}

const Item = styled.div``

const InstallerCountingSetupsInstallPageForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => ({
        items: props.countingSetup.counters.map((value) => ({
            counter_id: value.id,
            gateway_id: value.gateway,
            gateway_uid: null,
            sensor_1_uid: null,
            distance: null,
            sensor_2_uid: null
        }))
    }),
    validationSchema: Yup.object().shape({
        items: Yup.array().of(
            Yup.object().shape({
                gateway_uid: Yup.number()
                    .typeError("Must be a number")
                    .notRequired()
                    .nullable(),
                sensor_1_uid: Yup.number()
                    .typeError("Must be a number")
                    .required("Sensor 1 UID is required"),
                distance: Yup.number()
                    .typeError("Must be a number")
                    .notRequired()
                    .nullable(),
                sensor_2_uid: Yup.number()
                    .typeError("Must be a number")
                    .notRequired()
                    .nullable()
            })
        )
    }),
    handleSubmit: (values, {props, setSubmitting, setStatus}) => {
        // Check if gateway node uid is empty or null using isEmpty function
        // Check if sensor 2 uid is empty or null using isEmpty function
        setStatus(undefined)
        const data: InstallCountingSetupInput[] = Array<InstallCountingSetupInput>()
        values.items.forEach(value => {
            if (value.sensor_1_uid === null) return
            data.push({
                counter_id: value.counter_id,
                gateway_id: value.gateway_id,
                gateway_uid: value.gateway_uid,
                sensor_1_uid: value.sensor_1_uid,
                sensor_2_uid: value.sensor_2_uid,
                distance: value.distance ?? 7,
                space: props.countingSetup.space
            })
        })
        props.mutation.mutateAsync(data)
            .then(res => {
                setSubmitting(false)
                setStatus("Successfully installed the countingsetup")
                setTimeout(() => {
                    // Check if is undefined
                    if (res.data.id === 'undefined') {
                        props.toast.addToast({
                            content: "Error while trying to install the countingsetup",
                            type: "ERROR",
                            duration: 3000
                        })
                    } else {
                        props.history.push(`/installer/countingsetups/view/${res.data.id}`)
                    }
                }, 2000)
            }).catch(err => {
                setSubmitting(false)
                setStatus(err.error ?? "Error while trying to install the countingsetup")
            })
    }
})(withToast(InnerForm))

export default InstallerCountingSetupsInstallPageForm
