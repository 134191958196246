import React from 'react'
import {FormikProps, withFormik} from 'formik'
import * as Yup from 'yup'
import generateID from '../../../helpers/id'
import {PlusCircle} from '@styled-icons/boxicons-regular'
import {MapMarkerAlt} from '@styled-icons/fa-solid'
import {selectLocation, SelectLocationDialog} from '../../../components/modals/SelectLocation/SelectLocation'
import {UseMutationResult} from 'react-query'
import {Workspace} from '../../../models/workspace'
import {CreateWorkspaceInput} from '../../../hooks/workspaces'
import { History } from 'history'
import {Cross} from '@styled-icons/entypo'
import {ErrorWrapper, ErrorItem,
    FormButton, FormGroup, FormInline, FormInlineColl, FormInput, FormLabel, FormTextarea, FormTextAreaWrapper } from '../../../styles/shared'
import {redirectHandler} from '../../../helpers/handlers'
import {AxiosResponse} from 'axios'

interface FormValues {
    name: string
    description: string
    notes: string[]
    location: {
        latitude: number|null
        longitude: number|null
    }
    subdomain: string
}

interface FormProps {
    mutation: UseMutationResult<AxiosResponse<Workspace>, Error, CreateWorkspaceInput, unknown>
    history: History
    subdomains: string[]
    initialName?: string
    initialDescription?: string
    initialNotes?: string[]
    initialLocation?: {
        latitude: number
        longitude: number
    }
    initialSubdomain?: string
}

const innerForm: React.FC<FormProps & FormikProps<FormValues>> = (props) => {
    const addNote = () => {
        props.setValues(oldValues => ({
            ...oldValues,
            notes: [...oldValues.notes, ""]
        }))
    }

    const removeNote = (index: number) => {
        props.setValues(oldValues => ({
            ...oldValues,
            notes: oldValues.notes.filter((value, index1) => index1 !== index)
        }))
    }

    const clickSelectLocation = async () => {
        await selectLocation(SelectLocationDialog, {location: props.values.location.latitude != null && props.values.location.longitude != null ? {latitude: props.values.location.latitude, longitude: props.values.location.longitude} : undefined})
            .then(res => {
                if (res !== null) {
                    props.setFieldValue('location.latitude', res.location.latitude)
                    props.setFieldValue('location.longitude', res.location.longitude)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    return(
        <form className={"mt-5"} onSubmit={props.handleSubmit}>
            {
                props.status != null
                &&
                <div className={`shadow-none my-3 p-2 ${props.status.toLowerCase().includes("error") ? "bg-red-300" : "bg-green-300"} items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex`} role={"alert"}>
                    <span className={`flex rounded-full ${props.status.toLowerCase().includes("error") ? "bg-red-600" : "bg-green-600"} text-white uppercase px-2 py-1 text-xs font-bold mr-3`}>Status</span>
                    <span className={"font-semibold text-white mr-2 text-left flex-auto"}>{props.status}</span>
                </div>
            }
            <FormGroup>
                <FormLabel htmlFor={"name"}>Name</FormLabel>
                <FormInput onChange={props.handleChange} onBlur={props.handleBlur} type={"string"} name={"name"} id={"name"} value={props.values.name}/>
                <ErrorWrapper>
                    {
                        props.errors.name && props.touched.name && <ErrorItem>{props.errors.name}</ErrorItem>
                    }
                </ErrorWrapper>
            </FormGroup>
            <FormGroup>
                <FormLabel htmlFor={"description"}>Description</FormLabel>
                <FormTextarea onChange={props.handleChange} onBlur={props.handleBlur} name={"description"} id={"description"} value={props.values.description}/>
                <ErrorWrapper>
                    {
                        props.errors.description && props.touched.description && <ErrorItem>{props.errors.description}</ErrorItem>
                    }
                </ErrorWrapper>
            </FormGroup>
            <FormGroup>
                <FormLabel htmlFor={"notes"}>
                    Notes
                    <PlusCircle onClick={addNote}/>
                </FormLabel>
                {
                    props.values.notes.map((value, index) => (
                        <FormTextAreaWrapper key={generateID({page: 'clients-new-page-form', slug: 'notes', index: index})}>
                            <FormTextarea onChange={props.handleChange} onBlur={props.handleBlur} name={`notes[${index}]`} id={"notes"} value={value}/>
                            <Cross onClick={() => removeNote(index)}/>
                        </FormTextAreaWrapper>
                    ))
                }
                <ErrorWrapper>
                    {
                        props.errors.description && props.touched.description && <ErrorItem>{props.errors.description}</ErrorItem>
                    }
                </ErrorWrapper>
            </FormGroup>
            <FormGroup>
                <FormLabel>
                    Location
                    <MapMarkerAlt onClick={clickSelectLocation}/>
                </FormLabel>
                <FormInline>
                    <FormInlineColl>
                        <FormInput onChange={props.handleChange} onBlur={props.handleBlur} step={"any"} type={"number"} name={"location.latitude"} id={"location.latitude"} placeholder={"Latitude"} value={props.values.location.latitude ?? ""}/>
                        <ErrorWrapper>
                            {
                                props.errors.location?.latitude && props.touched.location?.latitude && <ErrorItem>{props.errors.location?.latitude}</ErrorItem>
                            }
                        </ErrorWrapper>
                    </FormInlineColl>
                    <FormInlineColl>
                        <FormInput onChange={props.handleChange} onBlur={props.handleBlur} step={"any"} type={"number"} name={"location.longitude"} id={"location.longitude"} placeholder={"Longitude"} value={props.values.location.longitude ?? ""}/>
                        <ErrorWrapper>
                            {
                                props.errors.location?.longitude && props.touched.location?.longitude && <ErrorItem>{props.errors.location?.longitude}</ErrorItem>
                            }
                        </ErrorWrapper>
                    </FormInlineColl>
                </FormInline>
            </FormGroup>
            <FormGroup>
                <FormLabel htmlFor={"subdomain"}>Subdomain</FormLabel>
                <FormInput onChange={props.handleChange} onBlur={props.handleBlur} type={"string"} name={"subdomain"} id={"subdomain"} placeholder={"vleuten"} value={props.values.subdomain}/>
                <small>Use it like this, value before .parkeagle.com. So in order to use vleuten.parkeagle.com you need to fill in vleuten.</small>
                <ErrorWrapper>
                    {
                        props.errors.subdomain && props.touched.subdomain && <ErrorItem>{props.errors.subdomain}</ErrorItem>
                    }
                </ErrorWrapper>
            </FormGroup>
            <FormButton type={"submit"} disabled={
                props.isSubmitting ||
                !!(props.errors.name && props.touched.name)
            }>
                Submit
            </FormButton>
        </form>
    );
}



const ClientsNewPageForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: props => ({
        name: props.initialName ?? "",
        description: props.initialDescription ?? "",
        notes: props.initialNotes ?? new Array<string>(),
        location: {
            latitude: props.initialLocation?.latitude ?? null,
            longitude: props.initialLocation?.longitude ?? null
        },
        subdomain: props.initialSubdomain ?? ""
    }),
    validationSchema: Yup.object().shape({
        name: Yup.string()
            .required("Name is required"),
        description: Yup.string()
            .notRequired(),
        notes: Yup.array(),
        location: Yup.object().shape({
            latitude: Yup
                .mixed()
                .required('Latitude is required')
                .test('validateCoordinate', 'Latitude must be a coordinate', value => new RegExp('^(\\+|-)?(?:90(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]+)?))$').test(value)),
            longitude: Yup
                .mixed()
                .required('Longitude is required')
                .test('validateCoordinate', 'Longitude must be a coordinate', value => new RegExp('^(\\+|-)?(?:180(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]+)?))$').test(value))
        }),
        subdomain: Yup.string()
            .notRequired()
    }),
    validate: (values, props) => {
        if (props.subdomains.includes(values.subdomain.toLowerCase())) {
            return {
                'subdomain': 'Subdomain must be unique'
            }
        }
        return {}
    },
    handleSubmit: (values, {props, setSubmitting, setStatus}) => {
        setStatus(undefined)
        props.mutation.mutateAsync({
            name: values.name,
            description: values.description,
            notes: values.notes,
            location: {
                latitude: values.location.latitude ?? 0,
                longitude: values.location.longitude ?? 0
            },
            subdomain: values.subdomain.toLowerCase()
        }).then(res => {
            setSubmitting(false)
            setStatus("Successfully created the client")
            setTimeout(() => {
                redirectHandler(res.data.id, `/clients/view/${res.data.id}`, `/clients`, props.history)
            }, 2000)
        }).catch(err => {
            setSubmitting(false)
            setStatus(err.error ?? "Error while trying to create the client")
        })
    }
})(innerForm)

export default ClientsNewPageForm
