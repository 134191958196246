export const isUndefined = (val: any) => val === null || val === undefined || val === 'undefined'

export function generateUUID(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (char) => {
        let random = Math.random() * 16 | 0;
        let value = char === "x" ? random : (random % 4 + 8);
        return value.toString(16);
    });
}

export function countUnique(iterable: Iterable<any>): number {
    return new Set(iterable).size;
}
