import React from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {CenteredError} from '../../../styles/shared'
import Loading from '../../../components/loading/Loading'
import HeaderComponent from '../../../components/header/Header'
import ContentComponent from '../../../components/content/Content'
import {installCountingSetup, InstallCountingSetupInput, useCountingSetup} from '../../../hooks/countingsetups'
import CountingSetupsInstallPageForm from './CountingSetupsInstallPageForm'
import {useMutation} from 'react-query'
import {GetCountingSetup} from '../../../models/groups'
import {isUndefined} from '../../../helpers/helpers'
import {AxiosResponse} from 'axios'

interface Params {
    id: string
}

const CountingSetupsInstallPage = () => {
    const { id } = useParams<Params>()

    const { data, error, isLoading } = useCountingSetup(id)

    const mutation = useMutation<AxiosResponse<GetCountingSetup>, Error, InstallCountingSetupInput[]>((_input) => installCountingSetup(id, _input), {})
    const history = useHistory()

    if (isLoading) return <CenteredError><Loading/></CenteredError>
    if (error) return <CenteredError>Error occurred while fetching: {error.message}</CenteredError>
    if (data == null || isUndefined(data?.id)) return <CenteredError>Data is undefined</CenteredError>

    return(
        <>
            <HeaderComponent title={data.display_name} subtitle={data.description}/>
            <ContentComponent>
                <CountingSetupsInstallPageForm countingSetup={data} mutation={mutation} history={history}/>
            </ContentComponent>
        </>
    )
}

export default CountingSetupsInstallPage
