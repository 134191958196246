import styled from 'styled-components'

export const CenteredError = styled.div.attrs({
    className: "p-3"
})`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SubHeader = styled.h2.attrs({
    className: "text-2xl font-bold leading-9 text-gray-900 sm:text-2xl sm:truncate"
})``

export const Table = styled.table.attrs({
    className: "min-w-full divide-y divide-gray-200"
})``

export const Thead = styled.thead.attrs({
    className: "bg-gray-50"
})``

export const Th = styled.th.attrs({
    className: "px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
})``

export const EditTh = styled.th.attrs({
    className: "relative px-6 py-3"
})``

export const Tbody = styled.tbody.attrs({
    className: "bg-white divide-y divide-gray-200"
})``

export const Td = styled.td.attrs({
    className: "px-4 py-4 text-sm whitespace-nowrap"
})``

export const ActionTd = styled.td.attrs({
    className: "px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
})``

export const FormGroup = styled.div.attrs({
    className: "mb-4"
})``

export const FormInline = styled.div.attrs({
    className: "flex flex-wrap mb-4"
})``

export const FormInlineColl = styled.div.attrs({
    className: "w-full md:w-1/2 flex items-center"
})`
  &:first-child {
    padding-right: 5px;
  }
  &:last-child {
    padding-left: 5px;
  }
`

export const FormLabel = styled.label.attrs({
    className: "flex text-gray-700 text-sm font-bold mb-2"
})`
  display: flex;
  align-items: center;
  
  & > svg {
    width: 20px;
    height: auto;
    margin-left: 10px;
    cursor: pointer;
  }
`

export const FormInputWrapper = styled.div.attrs({
    className: "flex"
})`
  & > svg {
    width: 30px;
    cursor: pointer;
  }
`

export const IconDivWrapper = styled.div.attrs({
    className: "flex justify-between w-full"
})`
  & > svg {
    width: 25px;
    height: auto;
    cursor: pointer;
  }
`

export const FormInput = styled.input.attrs({
    className: "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
})``

export const FormCheckbox = styled.input.attrs({
    className: ""
})``

export const FormRadioButtonWrapper = styled.div`
  display: flex;
  padding: 2px 4px;
  align-items: center;
  &:not(:last-of-type) {
    margin-right: 10px;
  }
`

export const FormRadioButtonLabel = styled.label.attrs({
    className: "text-sm"
})``

export const FormRadioButton = styled.input`
  &:checked, &:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  &:checked + label, &:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
  }
  &:checked + label:before, &:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background: #F7F7F7;
  }
  &:checked + label:after, &:not(:checked) + label:after {
    content: '';
    width: 16px;
    height: 16px;
    background: #1482FF;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  &:not(:checked):hover + label:after {
    background: #1482FF;
    opacity: 1;
    transform: scale(1);
  }
  &:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }
  &:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
`

export const FormSelect = styled.select.attrs({
    className: "shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
})``

export const FormTextAreaWrapper = styled.div.attrs({
    className: "flex"
})`
  & > svg {
    width: 30px;
    cursor: pointer;
  }
`

export const FormTextarea = styled.textarea.attrs({
    className: "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
})``

export const FormButton = styled.button.attrs({
    className: "transition-all bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
})`
  &:disabled {
    opacity: .8;
  }
`

export const FormCancelButton = styled.button.attrs({
    className: "transition-all bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
})`
  &:disabled {
    opacity: .8;
  }
`

export const ErrorWrapper = styled.div.attrs({
    className: "d-block"
})``

export const ErrorItem = styled.div`
  font-size: 10px;
  color: #F44336;
  padding: .25rem;
`
