import React from 'react'
import HeaderComponent from '../../../components/header/Header'
import ContentComponent from '../../../components/content/Content'
import ClientsList from '../../../components/clients/ClientsList/ClientsList'

const InstallerPage = () => {
    return (
        <>
            <HeaderComponent title={"Klanten"}/>
            <ContentComponent>
                <ClientsList prefix={`/installer/clients/view`} requestUrl={`/api/v1/installer/clients/`}/>
            </ContentComponent>
        </>
    )
}

export default InstallerPage
