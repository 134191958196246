import React from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useCountingSetup} from '../../../../hooks/countingsetups'
import {AxiosResponse} from 'axios'
import {useMutation} from 'react-query'
import {GetNodeAdmin} from '../../../../models/node'
import {
    deleteInstallerNodeAdmin,
    newInstallerNodeAdmin,
    NewNodeAdminInput, patchInstallerNodeAdmin,
    PatchPutNodeAdminInput, putInstallerNodeAdmin
} from '../../../../hooks/nodeadmins'
import { CenteredError } from '../../../../styles/shared'
import Loading from '../../../../components/loading/Loading'
import HeaderComponent from '../../../../components/header/Header'
import ContentComponent from '../../../../components/content/Content'
import {isUndefined} from '../../../../helpers/helpers'
import InstallerCountingSetupsEditInstallPageForm from './InstallerCountingSetupsEditInstallPageForm'

interface Params {
    id: string
}

const InstallerCountingSetupsEditInstallPage = () => {
    const { id } = useParams<Params>()

    const { data, error, isLoading, refetch } = useCountingSetup(id, `/api/v1/installer/countingsetups/`)

    const patchMutation = useMutation<AxiosResponse<GetNodeAdmin>, Error, PatchPutNodeAdminInput>((pData) => patchInstallerNodeAdmin(pData.id, pData.data), {})
    const putMutation = useMutation<AxiosResponse<GetNodeAdmin>, Error, PatchPutNodeAdminInput>((pData) => putInstallerNodeAdmin(pData.id, pData.data), {})
    const newMutation = useMutation<AxiosResponse<GetNodeAdmin>, Error, NewNodeAdminInput>(newInstallerNodeAdmin, {})
    const deleteMutation = useMutation<AxiosResponse<boolean>, Error, string>(deleteInstallerNodeAdmin, {})
    const history = useHistory()

    if (isLoading) return <CenteredError><Loading/></CenteredError>
    if (error) return <CenteredError>Error occurred while fetching: {error.message}</CenteredError>
    if (data == null || isUndefined(data?.id)) return <CenteredError>Data is undefined</CenteredError>

    return(
        <>
            <HeaderComponent title={data.display_name} subtitle={data.description}/>
            <ContentComponent>
                <InstallerCountingSetupsEditInstallPageForm countingSetup={data} newMutation={newMutation} deleteMutation={deleteMutation} patchMutation={patchMutation} putMutation={putMutation} refetch={refetch} history={history}/>
            </ContentComponent>
        </>
    )
}

export default InstallerCountingSetupsEditInstallPage
