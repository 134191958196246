import React, {useEffect} from 'react'
import {ActionTd, CenteredError, EditTh, SubHeader, Table, Tbody, Td, Th, Thead} from '../../../styles/shared'
import Loading from '../../loading/Loading'
import styled from 'styled-components'
import generateID from '../../../helpers/id'
import dayjs from 'dayjs'
import {Link} from 'react-router-dom'
import ShadowTable from '../../tables/ShadowTable/ShadowTable'
import axios from 'axios'
import {useQuery} from 'react-query'
import Pagination from '../../buttons/pagination/Pagination'
import {Space} from '../../../models/space'

interface Props {
    viewPrefix?: string
    ids?: string[]
    title?: boolean
    name?: string
}

interface Result {
    count: number
    items: Space[]
}

const SpacesList = ({ viewPrefix='/spaces/view', name = '', ids, title=true }: Props) => {
    const [page, setPage] = React.useState(0)

    const request = (page: number) => {
        return axios.get(`/api/v1/spaces/?_start=${page === 0 ? 0 : page*25}&_end=${(page+1)*25}&_name=${name}${(ids != null && ids?.length > 0) ? `&_spaces=${ids.join(',')}` : ''}`).then(res => res.data).catch(err => err.response.data)
    }

    const { data, error, isLoading, isPreviousData, isFetching } = useQuery<Result, Error>(['spaces', page, name], () => request(page), { keepPreviousData: true })

    useEffect(() => {
        setPage(0)
    }, [name])

    if (isLoading || isFetching) return <CenteredError><Loading/></CenteredError>
    if (error) return <CenteredError>Error occurred while fetching: {error.message}</CenteredError>
    if (data == null || data?.items == null) return <CenteredError>Data is undefined</CenteredError>
    if (data.items.length === 0) return <CenteredError>No Spaces found</CenteredError>

    return (
        <Wrapper>
            {
                title
                &&
                <div className={"mb-3"}>
                    <SubHeader>Spaces</SubHeader>
                </div>
            }
            <ShadowTable>
                <Table>
                    <Thead>
                        <tr>
                            <Th scope={"col"}>Name</Th>
                            <Th scope={"col"}>Created</Th>
                            <Th scope={"col"}>Updated</Th>
                            <EditTh scope={"col"} className={"relative px-6 py-3"}>
                                <span className={"sr-only"}>View</span>
                            </EditTh>
                        </tr>
                    </Thead>
                    <Tbody>
                        {
                            data.items.map((value, index) => (
                                <tr key={generateID({page: 'nodes-list-item', slug: value.id, index: index})}>
                                    <Td>{value.display_name}</Td>
                                    <Td>{dayjs(value.created_at).fromNow()}</Td>
                                    <Td>{dayjs(value.updated_at).fromNow()}</Td>
                                    <ActionTd>
                                        <Link to={`${viewPrefix}/${value.id}`} className={"text-blue-500 hover:text-blue-700"}>View</Link>
                                    </ActionTd>
                                </tr>
                            ))
                        }
                    </Tbody>
                </Table>
            </ShadowTable>
            <Pagination count={data.count} page={page} action={setPage} isPreviousData={isPreviousData}/>
        </Wrapper>
    )
}

const Wrapper = styled.div.attrs({
    className: "flex flex-col mt-3"
})``

export default SpacesList
