import React from 'react'
import {MapProvider} from 'react-map-gl';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import DashboardLayout from '../components/layout/Dashboard/Dashboard'
import HomePage from '../views/Home/HomePage/HomePage'
import ClientsPage from '../views/Clients/ClientsPage/ClientsPage'
import { QueryClientProvider } from 'react-query'
import {queryClient} from '../config/queryclient'
import ClientsViewPage from '../views/Clients/ClientsViewPage/ClientsViewPage'
import ClientsNewPage from '../views/Clients/ClientsNewPage/ClientsNewPage'
import CountingSetupsPage from '../views/CountingSetups/CountingSetupsPage/CountingSetupsPage'
import CountingSetupsViewPage from '../views/CountingSetups/CountingSetupsViewPage/CountingSetupsViewPage'
import CountingSetupsNewPage from '../views/CountingSetups/CountingSetupsNewPage/CountingSetupsNewPage'
import NodesPage from '../views/Nodes/NodesPage/NodesPage'
import NodesViewPage from '../views/Nodes/NodesViewPage/NodesViewPage'
import NodesNewPage from '../views/Nodes/NodesNewPage/NodesNewPage'
import CountingSetupsInstallPage from '../views/CountingSetups/CountingSetupsInstallPage/CountingSetupsInstallPage'
import InstallerPage from '../views/Installer/InstallerPage/InstallerPage'
import InstallerLayout from '../components/layout/Installer/Installer'
import InstallerClientsViewPage from '../views/Installer/Clients/InstallerClientsViewPage/InstallerClientsViewPage'
import InstallerCountingSetupsViewPage from '../views/Installer/CountingSetups/InstallerCountingSetupsViewPage/InstallerCountingSetupsViewPage'
import SpacesPage from '../views/Spaces/SpacesPage/SpacesPage'
import SpacesNewPage from '../views/Spaces/SpacesNewPage/SpacesNewPage'
import SpacesViewPage from '../views/Spaces/SpacesViewPage/SpacesViewPage'
import CountingSetupsEditPage from '../views/CountingSetups/CountingSetupsEditPage/CountingSetupsEditPage'
import NodesEditPage from '../views/Nodes/NodesEditPage/NodesEditPage'
import InstallerCountingSetupsInstallPage
    from '../views/Installer/CountingSetups/InstallerCountingSetupsInstallPage/InstallerCountingSetupsInstallPage'
import CountingSetupsEditInstallPage
    from '../views/CountingSetups/CountingSetupsEditInstallPage/CountingSetupsEditInstallPage'
import ToastContextWrapper from '../components/toast/toastmanager'
import InstallerCountingSetupsEditInstallPage
    from '../views/Installer/CountingSetups/InstallerCountingSetupsEditInstallPage/InstallerCountingSetupsEditInstallPage';
import RedirectPage from "../views/Redirect/RedirectPage/RedirectPage";

const APP_HOME_URI = '/'

const APP_CLIENTS_URI = '/clients'
const APP_CLIENTS_NEW_URI = '/clients/new'
const APP_CLIENTS_VIEW_URI = '/clients/view/:id'
const APP_CLIENTS_ROUTES = [APP_CLIENTS_URI, APP_CLIENTS_NEW_URI, APP_CLIENTS_VIEW_URI]

const APP_COUNTINGSETUPS_URI = '/countingsetups'
const APP_COUNTINGSETUPS_NEW_URI = '/countingsetups/spaces/:id/new'
const APP_COUNTINGSETUPS_VIEW_URI = '/countingsetups/view/:id'
const APP_COUNTINGSETUPS_EDIT_URI = '/countingsetups/edit/:id'
const APP_COUNTINGSETUPS_INSTALL_URI = '/countingsetups/install/:id'
const APP_COUNTINGSETUPS_EDIT_INSTALL_URI = '/countingsetups/edit-install/:id'

const APP_COUNTINGSETUPS_ROUTES = [APP_COUNTINGSETUPS_URI, APP_COUNTINGSETUPS_NEW_URI, APP_COUNTINGSETUPS_VIEW_URI, APP_COUNTINGSETUPS_EDIT_URI, APP_COUNTINGSETUPS_INSTALL_URI, APP_COUNTINGSETUPS_EDIT_INSTALL_URI]

const APP_NODES_URI = '/nodes'
const APP_NODES_VIEW_URI = '/nodes/view/:id'
const APP_NODES_EDIT_URI = '/nodes/edit/:id'
const APP_NODES_NEW_URI = '/nodes/new'

const APP_NODES_ROUTES = [APP_NODES_URI, APP_NODES_VIEW_URI, APP_NODES_EDIT_URI, APP_NODES_NEW_URI]

const APP_SPACES_URI = '/spaces'
const APP_SPACES_VIEW_URI = '/spaces/view/:id'
const APP_SPACES_NEW_URI = '/spaces/new'

const APP_SPACES_ROUTES = [APP_SPACES_URI, APP_SPACES_VIEW_URI, APP_SPACES_NEW_URI]

const APP_REDIRECT_URI = '/redirect/:encodedLinkMain/:encodedLinkSecondary'

const APP_REDIRECT_ROUTES = [APP_REDIRECT_URI]

const APP_DASHBOARD_ROUTES = [APP_HOME_URI, ...APP_CLIENTS_ROUTES, ...APP_COUNTINGSETUPS_ROUTES, ...APP_NODES_ROUTES, ...APP_SPACES_ROUTES, ...APP_REDIRECT_ROUTES]

const APP_INSTALLER_URI = '/installer'
const APP_INSTALLER_CLIENT_VIEW_URI = '/installer/clients/view/:id'

const APP_INSTALLER_COUNTINGSETUP_VIEW_URI = '/installer/countingsetups/view/:id'
const APP_INSTALLER_COUNTINGSETUP_INSTALL_URI = '/installer/countingsetups/install/:id'
const APP_INSTALLER_COUNTINGSETUP_EDIT_INSTALL_URI = '/installer/countingsetups/edit-install/:id'

const APP_INSTALLER_ROUTES = [APP_INSTALLER_URI, APP_INSTALLER_CLIENT_VIEW_URI, APP_INSTALLER_COUNTINGSETUP_VIEW_URI, APP_INSTALLER_COUNTINGSETUP_INSTALL_URI, APP_INSTALLER_COUNTINGSETUP_EDIT_INSTALL_URI]

const Router = () => (
    <QueryClientProvider client={queryClient}>
        <ToastContextWrapper>
            <MapProvider>
                <BrowserRouter>
                <Switch>
                    <Route exact path={APP_INSTALLER_ROUTES}>
                        <InstallerLayout>
                            <Route exact path={APP_INSTALLER_URI} component={InstallerPage}/>
                            <Route exact path={APP_INSTALLER_CLIENT_VIEW_URI} component={InstallerClientsViewPage}/>
                            <Route exact path={APP_INSTALLER_COUNTINGSETUP_VIEW_URI} component={InstallerCountingSetupsViewPage}/>
                            <Route exact path={APP_INSTALLER_COUNTINGSETUP_INSTALL_URI} component={InstallerCountingSetupsInstallPage}/>
                            <Route exact path={APP_INSTALLER_COUNTINGSETUP_EDIT_INSTALL_URI} component={InstallerCountingSetupsEditInstallPage}/>
                        </InstallerLayout>
                    </Route>
                    {
                        ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') || window.location.hostname.split(".")[0] === 'scimitar')
                        &&
                        <Route exact path={APP_DASHBOARD_ROUTES}>
                            <DashboardLayout>
                                <Route exact path={APP_HOME_URI} component={HomePage}/>
                                {/*  Clients  */}
                                <Route exact path={APP_CLIENTS_URI} component={ClientsPage}/>
                                <Route exact path={APP_CLIENTS_NEW_URI} component={ClientsNewPage}/>
                                <Route exact path={APP_CLIENTS_VIEW_URI} component={ClientsViewPage}/>
                                {/*  CountingSetups  */}
                                <Route exact path={APP_COUNTINGSETUPS_URI} component={CountingSetupsPage}/>
                                <Route exact path={APP_COUNTINGSETUPS_NEW_URI} component={CountingSetupsNewPage}/>
                                <Route exact path={APP_COUNTINGSETUPS_VIEW_URI} component={CountingSetupsViewPage}/>
                                <Route exact path={APP_COUNTINGSETUPS_EDIT_URI} component={CountingSetupsEditPage}/>
                                <Route exact path={APP_COUNTINGSETUPS_INSTALL_URI} component={CountingSetupsInstallPage}/>
                                <Route exact path={APP_COUNTINGSETUPS_EDIT_INSTALL_URI} component={CountingSetupsEditInstallPage}/>
                                {/*  Nodes  */}
                                <Route exact path={APP_NODES_URI} component={NodesPage}/>
                                <Route exact path={APP_NODES_VIEW_URI} component={NodesViewPage}/>
                                <Route exact path={APP_NODES_EDIT_URI} component={NodesEditPage}/>
                                <Route exact path={APP_NODES_NEW_URI} component={NodesNewPage}/>
                                {/*  Spaces  */}
                                <Route exact path={APP_SPACES_URI} component={SpacesPage}/>
                                <Route exact path={APP_SPACES_NEW_URI} component={SpacesNewPage}/>
                                <Route exact path={APP_SPACES_VIEW_URI} component={SpacesViewPage}/>
                                {/* Intermediate redirect */}
                                <Route exact path={APP_REDIRECT_URI} component={RedirectPage}/>
                            </DashboardLayout>
                        </Route>
                    }
                </Switch>
            </BrowserRouter>
            </MapProvider>
        </ToastContextWrapper>
    </QueryClientProvider>
)

export default Router
