import React from 'react'
import {AddToQueue} from '@styled-icons/boxicons-regular'
import HeaderComponent from '../../../components/header/Header'
import ContentComponent from '../../../components/content/Content'
import ActionLink from '../../../components/buttons/action/ActionLink'
import ClientsList from '../../../components/clients/ClientsList/ClientsList'
import SearchList from '../../../components/search/SearchList'

const ClientsPage = () => {
    const [name, setName] = React.useState<string>('')

    return(
        <>
            <HeaderComponent title={"Clients"} subtitleComponent={<SearchList value={name} action={setName}/>}>
                <ActionLink to={`/clients/new`} icon={<AddToQueue/>}>
                    New
                </ActionLink>
            </HeaderComponent>
            <ContentComponent>
                <ClientsList name={name}/>
            </ContentComponent>
        </>
    )
}

export default ClientsPage
