import React from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {
    InstallCountingSetupInput,
    installerInstallCountingSetup,
    useCountingSetup
} from '../../../../hooks/countingsetups'
import {CenteredError} from '../../../../styles/shared'
import Loading from '../../../../components/loading/Loading'
import HeaderComponent from '../../../../components/header/Header'
import ContentComponent from '../../../../components/content/Content'
import InstallerCountingSetupsInstallPageForm from './InstallerCountingSetupsInstallPageForm'
import {useMutation} from 'react-query'
import {GetCountingSetup} from '../../../../models/groups'
import {AxiosResponse} from 'axios'
import {withToast, WithToastProps} from '../../../../components/toast/toastmanager';

interface Params {
    id: string
}

interface Props extends WithToastProps {}

const InstallerCountingSetupsInstallPage = ({ toast }: Props) => {
    const { id } = useParams<Params>()

    const { data, error, isLoading } = useCountingSetup(id, `/api/v1/installer/countingsetups/`)

    const mutation = useMutation<AxiosResponse<GetCountingSetup>, Error, InstallCountingSetupInput[]>((_input) => installerInstallCountingSetup(id, _input), {})
    const history = useHistory()

    if (isLoading) return <CenteredError><Loading/></CenteredError>
    if (error) return <CenteredError>Error occurred while fetching: {error.message}</CenteredError>
    if (data == null) return <CenteredError>Data is undefined</CenteredError>

    return(
        <>
            <HeaderComponent title={data.display_name} subtitle={data.description}/>
            <ContentComponent>
                <InstallerCountingSetupsInstallPageForm countingSetup={data} mutation={mutation} history={history} toast={toast}/>
            </ContentComponent>
        </>
    )
}

export default withToast(InstallerCountingSetupsInstallPage)
