import {useQuery, UseQueryResult} from 'react-query'
import {GetNode} from '../models/node'
import axios, {AxiosPromise} from 'axios'
import {GenericLazyReferenceField} from '../models/base'

export function useNode(id: string): UseQueryResult<GetNode, Error> {
    return useQuery<GetNode, Error>(`node.${id}`, () => fetchNode(id))
}

function fetchNode(id: string): Promise<GetNode> {
    return axios.get(`/api/v1/nodes/${id}`).then(res => res.data).catch(err => err.response.data)
}

export async function nodeExists(id: number, install?: boolean): Promise<boolean> {
    try {
        const resp = await axios.get(install ? `/api/v1/installer/nodes/${id}/exists` : `/api/v1/nodes/${id}/exists`)
        return resp.status === 200
    } catch (err) {
        return false
    }
}

export enum NodeType {
    Sensor="SENSOR",
    Gateway="GATEWAY"
}

export interface CreateNodeInput {
    display_name: string
    description: string
    uid: number
    node_type: string
    node_admins: CreateNodeAdminInput[]
}

export interface CreateNodeAdminInput {
    space: string
    product: GenericLazyReferenceField
    position: number[]
    orientation: number[]
}

export function createNode(data: CreateNodeInput): AxiosPromise<GetNode> {
    return axios.post('/api/v1/nodes/', data)
}

export function patchNode(id: string, data: object): AxiosPromise<GetNode> {
    return axios.patch(`/api/v1/nodes/${id}`, data)
}

export function deleteNode(id: string): AxiosPromise<boolean> {
    return axios.delete(`/api/v1/nodes/${id}`)
}
