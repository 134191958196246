import React, {useMemo, useRef, useState} from 'react';
import env from '../../../production.env'
import Map, {MapLayerMouseEvent, MapRef, Marker} from "react-map-gl";
import {MultiPointField} from "../../../models/location";
import styled from "styled-components";
import 'mapbox-gl/dist/mapbox-gl.css';
import {LngLat} from "mapbox-gl";

interface Props {
    name?: string | null
    location: MultiPointField
}

export default function SingleMapInfoView({ name, location }: Props) {
    const ref = useRef<MapRef>(null)
    const [coordinates, setCoordinates] = useState<LngLat>(new LngLat(52.35026259227887, 4.762992649377801));

    const [state, setState] = useState<any>({
        latitude: location.coordinates[0][0] ?? 52.35026259227887,
        longitude: location.coordinates[0][1] ?? 4.762992649377801,
        zoom: 15,
    })

    const markers = useMemo(() => location.coordinates.map((coordinate, index) => (
        <Marker
            longitude={coordinate[1]}
            latitude={coordinate[0]}
            key={`marker-${index}`}
        >
            {/* Can have the following classes: countingSetupMarker active activeCountingSetup */}
                <div className="countingSetupMarker">
                    <span>{name}</span>
                </div>
        </Marker>)
    ), [location.coordinates, name]);

    return (
        <Map
            reuseMaps
            id="singleMap"
            className="h-96"
            ref={ref}
            mapboxAccessToken={env.mapBoxToken}
            mapStyle={'mapbox://styles/mapbox/light-v10'}
            {...state}
            onMove={(evt: { viewState: any }) => setState(evt.viewState)}
            onMouseMove={(evt: MapLayerMouseEvent) => {
                setCoordinates(evt.lngLat)
            }}
        >
            <HoverWrapper>
                lng: {coordinates.lng}, lat: {coordinates.lat}
            </HoverWrapper>
            { markers && markers }
        </Map>
    );
}

const HoverWrapper = styled.div.attrs({
    className: "absolute shadow overflow-hidden border-b border-gray-200 bg-white m-3 py-2 px-4 font-medium"
})``
