import {useQuery} from 'react-query'
import {CountingSetup, GetCountingSetup} from '../models/groups'
import axios, {AxiosPromise} from 'axios'

export function useCountingSetup(id: string, requestUrl?: string) {
    return useQuery<GetCountingSetup, Error>(`countingsetup.${id}`, () => fetchCountingSetup(id, requestUrl))
}

function fetchCountingSetup(id: string, requestUrl?: string): Promise<GetCountingSetup> {
    return axios.get(`${requestUrl ?? `/api/v1/countingsetups/`}${id}`).then(res => res.data).catch(err => err.response.data)
}

export interface CreateCountingSetupInput {
    location: {
        lat: number
        lon: number
    }
    display_name: string
    notes: string[]
    description: string
    space: string
    name_positive: string
    name_negative: string
    between_positive: string
    between_negative: string
    number_of_lanes: number
    number_of_directions: number
    lane_1: number
    lane_2: number
    lane_3: number
    lane_4: number
    lane_5: number
    lane_6: number
}

export function createCountingSetup(data: CreateCountingSetupInput): AxiosPromise<CountingSetup> {
    return axios.post('/api/v1/countingsetups/', data)
}

export function patchCountingSetup(id: string, data: object): AxiosPromise<GetCountingSetup> {
    return axios.patch(`/api/v1/countingsetups/${id}`, data)
}

export interface InstallCountingSetupInput {
    counter_id: string
    gateway_id: string|null
    gateway_uid: number|null
    sensor_1_uid: number
    sensor_2_uid: number|null
    distance: number
    space: string
}

export function installCountingSetup(id: string, data: InstallCountingSetupInput[]): AxiosPromise<GetCountingSetup> {
    return axios.post(`/api/v1/countingsetups/${id}/install`, data)
}

export function installerInstallCountingSetup(id: string, data: InstallCountingSetupInput[]): AxiosPromise<GetCountingSetup> {
    return axios.post(`/api/v1/installer/countingsetups/${id}/install`, data)
}

export function deleteCountingSetup(id: string): AxiosPromise<boolean> {
    return axios.delete(`/api/v1/countingsetups/${id}`)
}
