import React from 'react'
import {useMutation} from 'react-query'
import {CountingSetup} from '../../../models/groups'
import {createCountingSetup, CreateCountingSetupInput} from '../../../hooks/countingsetups'
import {useHistory, useParams} from 'react-router-dom'
import {CenteredError} from '../../../styles/shared'
import CountingSetupsNewPageForm from './CountingSetupsNewPageForm'
import {useSpace} from '../../../hooks/spaces'
import Loading from '../../../components/loading/Loading'
import HeaderComponent from '../../../components/header/Header'
import ContentComponent from '../../../components/content/Content'
import {isUndefined} from '../../../helpers/helpers'
import {AxiosResponse} from 'axios'

interface Params {
    id: string
}

const CountingSetupsNewPage = () => {
    const { id } = useParams<Params>()

    const { data, error, isLoading } = useSpace(id ?? ``)

    const mutation = useMutation<AxiosResponse<CountingSetup>, Error, CreateCountingSetupInput>(createCountingSetup, {})
    const history = useHistory()

    if (isLoading) return <CenteredError><Loading/></CenteredError>
    if (error) return <CenteredError>Error occurred while fetching: {error.message}</CenteredError>
    if (data == null || isUndefined(data?.id)) return <CenteredError>Data is undefined</CenteredError>

    return(
        <>
            <HeaderComponent title={"New CountingSetup"}/>
            <ContentComponent>
                <CountingSetupsNewPageForm mutation={mutation} history={history} space={data}/>
            </ContentComponent>
        </>
    )
}

export default CountingSetupsNewPage
