import React, {SetStateAction} from 'react'
import styled from 'styled-components'

interface Props {
    value: string
    action: React.Dispatch<SetStateAction<string>>
}

const SearchList = ({ value, action }: Props) => (
    <div className="pt-2 relative mx-auto text-gray-600">
        <SearchInput placeholder={"Search...."} value={value} type={"text"} onChange={(e) => action(e.target.value)}/>
    </div>
)

const SearchInput = styled.input.attrs({
    className: "border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
})``

export default SearchList
