import React from 'react'
import {useParams} from 'react-router-dom'
import {useCountingSetup} from '../../../../hooks/countingsetups'
import {CenteredError} from '../../../../styles/shared'
import Loading from '../../../../components/loading/Loading'
import HeaderComponent from '../../../../components/header/Header'
import ContentComponent from '../../../../components/content/Content'
import CounterList from '../../../../components/counter/CounterList/CounterList'
import GatewayList from '../../../../components/gateway/GatewayList/GatewayList'

interface Params {
    id: string
}

const InstallerCountingSetupsViewPage = () => {
    const { id } = useParams<Params>()

    const { data, error, isLoading } = useCountingSetup(id, `/api/v1/installer/countingsetups/`)

    if (isLoading) return <CenteredError><Loading/></CenteredError>
    if (error) return <CenteredError>Error occurred while fetching: {error.message}</CenteredError>
    if (data == null) return <CenteredError>Data is undefined</CenteredError>

    return(
        <>
            <HeaderComponent title={data.display_name} subtitle={data.description}/>
            <ContentComponent>
                <div className={"flex mb-4 flex-wrap"}>
                    <div className={"w-full xl:w-1/2 pr-2"}>
                        <CounterList counters={data.counters} name_positive={data.name_positive} name_negative={data.name_negative}/>
                    </div>
                    <div className={"w-full xl:w-1/2 pl-2"}>
                        <GatewayList gateways={data.gateways} showActions={false}/>
                    </div>
                </div>
            </ContentComponent>
        </>
    )
}

export default InstallerCountingSetupsViewPage
