import React from 'react'
import {UseMutationResult} from 'react-query'
import {GetCountingSetup} from '../../../models/groups'
import {History} from 'history'
import {FormikProps, withFormik} from 'formik'
import * as Yup from 'yup'
import {
    ErrorItem,
    ErrorWrapper, FormButton,
    FormGroup,
    FormInline,
    FormInlineColl,
    FormInput,
    FormLabel, FormRadioButton, FormRadioButtonLabel, FormRadioButtonWrapper, FormSelect, FormTextarea
} from '../../../styles/shared'
import {MapMarkerAlt} from '@styled-icons/fa-solid'
import {selectLocation, SelectLocationDialog} from '../../../components/modals/SelectLocation/SelectLocation'
import styled from 'styled-components'
import {Counter} from '../../../models/products'
import {redirectHandler} from '../../../helpers/handlers'
import {AxiosResponse} from 'axios'
import {countUnique} from "../../../helpers/helpers";

interface FormValues {
    display_name: string
    description: string
    location: {
        latitude: number|null
        longitude: number|null
    }
    road_section_between_a: string
    road_section_between_b: string
    number_of_lanes: number
    number_of_directions: number
    space: string
    lane_1: any
    lane_2: any
    lane_3: any
    lane_4: any
    lane_5: any
    lane_6: any
    [key: string]: any;
}

interface FormProps {
    mutation: UseMutationResult<AxiosResponse<GetCountingSetup>, Error, object, unknown>
    history: History
    countingsetup: GetCountingSetup
}

const innerForm: React.FC<FormProps & FormikProps<FormValues>> = (props) => {
    const clickSelectLocation = async () => {
        await selectLocation(SelectLocationDialog, {location: props.values.location.latitude != null && props.values.location.longitude != null ? {latitude: props.values.location.latitude, longitude: props.values.location.longitude} : undefined, initialLocation: props.countingsetup.cs_space.location != null ? {latitude: props.countingsetup.cs_space.location.coordinates[0][0], longitude: props.countingsetup.cs_space.location.coordinates[0][1]} : undefined})
            .then(res => {
                if (res !== null) {
                    props.setFieldValue('location.latitude', res.location.latitude)
                    props.setFieldValue('location.longitude', res.location.longitude)
                    confirmStreetName(res.name)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const confirmStreetName = (name: string|null) => {
        if (window.confirm(`Fill the field with the following street name: ${name}`)) props.setFieldValue('display_name', name ?? "")
    }

    const clickSelectStreetName = async (field: string) => {
        await selectLocation(SelectLocationDialog, {location: props.values.location.latitude != null && props.values.location.longitude != null ? {latitude: props.values.location.latitude, longitude: props.values.location.longitude} : undefined})
            .then(res => {
                if (res !== null) {
                    props.setFieldValue(field, res.name ?? "")
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const onRadioClick = (name: string, value: number) => props.setFieldValue(name, value)

    return(
        <form className={"mt-5"} onSubmit={props.handleSubmit}>
            {
                props.status != null
                &&
                <div className={"shadow-none my-3 p-2 bg-green-300 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"} role="alert">
                    <span className={"flex rounded-full bg-green-600 text-white uppercase px-2 py-1 text-xs font-bold mr-3"}>Status</span>
                    <span className={"font-semibold text-white mr-2 text-left flex-auto"}>{props.status}</span>
                </div>
            }
            <FormGroup>
                <FormLabel>
                    Space
                </FormLabel>
                <FormInput onChange={props.handleChange} onBlur={props.handleBlur} type={"string"} name={`space`} id={`space`} placeholder={"Space ID"} disabled={true} value={props.values.space ?? ""}/>
                <ErrorWrapper>
                    {
                        props.errors.space && props.touched.space && <ErrorItem>{props.errors.space}</ErrorItem>
                    }
                </ErrorWrapper>
            </FormGroup>
            <FormGroup style={{ visibility: props.values.space.length > 0 ? "visible" : "hidden" }}>
                <FormLabel>
                    Location
                    <MapMarkerAlt onClick={clickSelectLocation}/>
                </FormLabel>
                <FormInline>
                    <FormInlineColl>
                        <FormInput onChange={props.handleChange} onBlur={props.handleBlur} step={"any"} type={"number"} name={"location.latitude"} id={"location.latitude"} placeholder={"Latitude"} value={props.values.location.latitude ?? ""}/>
                        <ErrorWrapper>
                            {
                                props.errors.location?.latitude && props.touched.location?.latitude && <ErrorItem>{props.errors.location?.latitude}</ErrorItem>
                            }
                        </ErrorWrapper>
                    </FormInlineColl>
                    <FormInlineColl>
                        <FormInput onChange={props.handleChange} onBlur={props.handleBlur} step={"any"} type={"number"} name={"location.longitude"} id={"location.longitude"} placeholder={"Longitude"} value={props.values.location.longitude ?? ""}/>
                        <ErrorWrapper>
                            {
                                props.errors.location?.longitude && props.touched.location?.longitude && <ErrorItem>{props.errors.location?.longitude}</ErrorItem>
                            }
                        </ErrorWrapper>
                    </FormInlineColl>
                </FormInline>
            </FormGroup>
            <FormGroup>
                <FormLabel htmlFor={"display_name"}>Name</FormLabel>
                <FormInput onChange={props.handleChange} onBlur={props.handleBlur} type={"string"} name={"display_name"} id={"display_name"} value={props.values.display_name}/>
                <ErrorWrapper>
                    {
                        props.errors.display_name && props.touched.display_name && <ErrorItem>{props.errors.display_name}</ErrorItem>
                    }
                </ErrorWrapper>
            </FormGroup>
            <FormGroup>
                <FormLabel htmlFor={"description"}>Description</FormLabel>
                <FormTextarea onChange={props.handleChange} onBlur={props.handleBlur} name={"description"} id={"description"} value={props.values.description}/>
                <ErrorWrapper>
                    {
                        props.errors.description && props.touched.description && <ErrorItem>{props.errors.description}</ErrorItem>
                    }
                </ErrorWrapper>
            </FormGroup>
            <FormGroup>
                <FormLabel>
                    Road section between
                </FormLabel>
                <FormInline>
                    <FormInlineColl>
                        <StreetNameWrapper>
                            <FormInput onChange={props.handleChange} onBlur={props.handleBlur} type={"string"} name={"road_section_between_a"} id={"road_section_between_a"} placeholder={""} value={props.values.road_section_between_a ?? ""}/>
                            <MapMarkerAlt onClick={() => clickSelectStreetName('road_section_between_a')}/>
                        </StreetNameWrapper>
                        <ErrorWrapper>
                            {
                                props.errors.road_section_between_a && props.touched.road_section_between_a && <ErrorItem>{props.errors.road_section_between_a}</ErrorItem>
                            }
                        </ErrorWrapper>
                    </FormInlineColl>
                    <FormInlineColl>
                        <StreetNameWrapper>
                            <FormInput onChange={props.handleChange} onBlur={props.handleBlur} type={"string"} name={"road_section_between_b"} id={"road_section_between_b"} placeholder={""} value={props.values.road_section_between_b ?? ""}/>
                            <MapMarkerAlt onClick={() => clickSelectStreetName('road_section_between_b')}/>
                        </StreetNameWrapper>
                        <ErrorWrapper>
                            {
                                props.errors.road_section_between_b && props.touched.road_section_between_b && <ErrorItem>{props.errors.road_section_between_b}</ErrorItem>
                            }
                        </ErrorWrapper>
                    </FormInlineColl>
                </FormInline>
            </FormGroup>
            <FormGroup>
                <FormLabel htmlFor={"number_of_lanes"}>Number of lanes</FormLabel>
                <div className={"flex w-full items-center flex-wrap"}>
                    {Array.from(Array(6).keys()).map(value => (
                        <FormRadioButtonWrapper key={Math.random()}>
                            <FormRadioButton onChange={() => onRadioClick('number_of_lanes', value+1)} onBlur={props.handleBlur} type={"radio"} value={value+1} checked={props.values.number_of_lanes === value+1} name={"number_of_lanes"} id={`number_of_lanes.${value+1}`} disabled />
                            <FormRadioButtonLabel htmlFor={`number_of_lanes.${value+1}`}>{value+1}</FormRadioButtonLabel>
                        </FormRadioButtonWrapper>
                    ))}
                </div>
                <ErrorWrapper>
                    {
                        props.errors.number_of_lanes && props.touched.number_of_lanes && <ErrorItem>{props.errors.number_of_lanes}</ErrorItem>
                    }
                </ErrorWrapper>
            </FormGroup>
            {/*<FormGroup>*/}
            {/*    <FormLabel htmlFor={"number_of_directions"}>Number of directions</FormLabel>*/}
            {/*    <div className={"flex w-full items-center flex-wrap"}>*/}
            {/*        {Array.from(Array(2).keys()).map(value => (*/}
            {/*            <FormRadioButtonWrapper key={Math.random()}>*/}
            {/*                <FormRadioButton onChange={() => onRadioClick('number_of_directions', value+1)} onBlur={props.handleBlur} type={"radio"} value={value+1} checked={props.values.number_of_directions === value+1} name={"number_of_directions"} id={`number_of_directions.${value+1}`}/>*/}
            {/*                <FormRadioButtonLabel htmlFor={`number_of_directions.${value+1}`}>{value+1}</FormRadioButtonLabel>*/}
            {/*            </FormRadioButtonWrapper>*/}
            {/*        ))}*/}
            {/*    </div>*/}
            {/*    <ErrorWrapper>*/}
            {/*        {*/}
            {/*            props.errors.number_of_directions && props.touched.number_of_directions && <ErrorItem>{props.errors.number_of_directions}</ErrorItem>*/}
            {/*        }*/}
            {/*    </ErrorWrapper>*/}
            {/*</FormGroup>*/}
            <hr className={"border-t-2 border-dotted mb-2"} />
            <FormLabel htmlFor='lane_1'>With multiple lanes in the same directions: start with the left most lane</FormLabel>
            {Array.from(Array(props.values.number_of_lanes).keys()).map(value => (
                <>
                    <FormGroup key={`lane_${value + 1}`}>
                        <FormLabel htmlFor={`lane_${value + 1}`}>Lane {value + 1} direction</FormLabel>
                        {/*// @ts-ignore*/}
                        <FormSelect onChange={props.handleChange} onBlur={props.handleBlur}
                                    name={`lane_${value + 1}`} id={`lane_${value + 1}`}
                                    value={props.values[`lane_${value+1}`]}>
                            <option value={""} disabled hidden>Choose a direction</option>
                            <option value={-1}>Direction 1 {`-> ${props.values.road_section_between_b}`}</option>
                            <option value={1}>Direction 2 {`-> ${props.values.road_section_between_a}`}</option>
                        </FormSelect>
                        <ErrorWrapper>
                            {
                                // @ts-ignore
                                props.errors[`lane_${value + 1}`] && props.touched[`lane_${value + 1}`] &&
                                <ErrorItem>{props.errors[`lane_${value + 1}`]}</ErrorItem>}
                        </ErrorWrapper>
                    </FormGroup>
                </>
            ))}
            <FormButton type={"submit"} disabled={
                props.isSubmitting ||
                !!(props.errors.display_name && props.touched.display_name)
            }>
                Submit
            </FormButton>
        </form>
    )
}

const StreetNameWrapper = styled.div`
  display: flex;
  
  & > svg {
    width: 15px;
    margin-left: 10px;
    margin-right: 5px;
    height: auto;
    color: black;
    cursor: pointer;
  }
`

const getNumberOfDirections = (counters: Counter[]): number => {
    const directions = new Set<number>()
    counters.forEach(value => {
        directions.add(value.count_direction)
    })
    return directions.size === 0 ? 1 : directions.size
}

interface InputData {
    [key: string]: any
}

const CountingSetupsEditPageForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => ({
        display_name: props.countingsetup.display_name ?? "",
        description: props.countingsetup.description ?? "",
        space: props.countingsetup.space,
        location: {
            latitude: props.countingsetup.location?.coordinates[0][0] ?? null,
            longitude: props.countingsetup.location?.coordinates[0][1] ?? null
        },
        road_section_between_a: props.countingsetup.name_positive ?? "",
        road_section_between_b: props.countingsetup.name_negative ?? "",
        number_of_lanes: props.countingsetup.counters.length,
        number_of_directions: getNumberOfDirections(props.countingsetup.counters), // TODO: Kan weg?
        lane_1: props.countingsetup.counters[0] && props.countingsetup.counters[0].count_direction ? props.countingsetup.counters[0].count_direction : null,
        lane_2: props.countingsetup.counters[1] && props.countingsetup.counters[1].count_direction ? props.countingsetup.counters[1].count_direction : null,
        lane_3: props.countingsetup.counters[2] && props.countingsetup.counters[2].count_direction ? props.countingsetup.counters[2].count_direction : null,
        lane_4: props.countingsetup.counters[3] && props.countingsetup.counters[3].count_direction ? props.countingsetup.counters[3].count_direction : null,
        lane_5: props.countingsetup.counters[4] && props.countingsetup.counters[4].count_direction ? props.countingsetup.counters[4].count_direction : null,
        lane_6: props.countingsetup.counters[5] && props.countingsetup.counters[5].count_direction ? props.countingsetup.counters[5].count_direction : null,
    }),
    validationSchema: Yup.object().shape({
        display_name: Yup.string()
            .required("Name is required"),
        description: Yup.string()
            .notRequired(),
        location: Yup.object().shape({
            latitude: Yup
                .mixed()
                .required('Latitude is required')
                .test('validateCoordinate', 'Latitude must be a coordinate', value => new RegExp('^(\\+|-)?(?:90(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]+)?))$').test(value)),
            longitude: Yup
                .mixed()
                .required('Longitude is required')
                .test('validateCoordinate', 'Longitude must be a coordinate', value => new RegExp('^(\\+|-)?(?:180(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]+)?))$').test(value))
        }),
        space: Yup.string()
            .required("Space is required"),
        road_section_between_a: Yup.string()
            .required("Road Section Between Left is required"),
        road_section_between_b: Yup.string()
            .required("Road Section Between Right is required"),
    }),
    handleSubmit: (values, {props, setSubmitting, setStatus}) => {
        setStatus(undefined)

        const data: InputData = {
            location: {
                lat: values.location.latitude ?? 0,
                lon: values.location.longitude ?? 0,
            },
            display_name: values.display_name,
            description: values.description,
            name_positive: values.road_section_between_a,
            name_negative: values.road_section_between_b,
            between_positive: `${values.road_section_between_b} -> ${values.road_section_between_a}`,
            between_negative: `${values.road_section_between_a} -> ${values.road_section_between_b}`,
            number_of_lanes: values.number_of_lanes,
            number_of_directions: countUnique([values.lane_1, values.lane_2, values.lane_3, values.lane_4, values.lane_5, values.lane_6].filter(lane => !['', null, undefined, NaN].includes(lane))) > 1 ? 2 : 1,
            lane_1: parseInt(values.lane_1),
            lane_2: parseInt(values.lane_2),
            lane_3: parseInt(values.lane_3),
            lane_4: parseInt(values.lane_4),
            lane_5: parseInt(values.lane_5),
            lane_6: parseInt(values.lane_6),
        }

        if (data.location.lat === (props.countingsetup.location?.coordinates[0][0] ?? null) && data.location.lon === (props.countingsetup.location?.coordinates[0][1] ?? null)) delete data['location']
        if (data.display_name === props.countingsetup.display_name) delete data['display_name']
        if (data.description === props.countingsetup.description) delete data['description']
        if (data.name_positive === props.countingsetup.name_positive) delete data['name_positive']
        if (data.name_negative === props.countingsetup.name_negative) delete data['name_negative']
        if (data.between_positive === props.countingsetup.between_positive) delete data['between_positive']
        if (data.between_negative === props.countingsetup.between_negative) delete data['between_negative']
        // Don't remove values below because for the backend `number_of_lanes` and `number_of_directions` are needed when changing lanes
        // if (data.number_of_lanes === props.countingsetup.counters.length) delete data['number_of_lanes']
        // if (data.number_of_directions === getNumberOfDirections(props.countingsetup.counters)) delete data['number_of_directions']
        // if (typeof props.countingsetup.counters[0] === "undefined" || data.lane_1 === props.countingsetup.counters[0]) delete data['lane_1']
        // if (typeof props.countingsetup.counters[1] === "undefined" || data.lane_2 === props.countingsetup.counters[1]) delete data['lane_2']
        // if (typeof props.countingsetup.counters[2] === "undefined" || data.lane_3 === props.countingsetup.counters[2]) delete data['lane_3']
        // if (typeof props.countingsetup.counters[3] === "undefined" || data.lane_4 === props.countingsetup.counters[3]) delete data['lane_4']
        // if (typeof props.countingsetup.counters[4] === "undefined" || data.lane_5 === props.countingsetup.counters[4]) delete data['lane_5']
        // if (typeof props.countingsetup.counters[5] === "undefined" || data.lane_6 === props.countingsetup.counters[5]) delete data['lane_6']

        if (Object.keys(data).length > 0) {
            props.mutation.mutateAsync(data).then(res => {
                setSubmitting(false)
                setStatus("Successfully updated the countingsetup")
                setTimeout(() => {
                    redirectHandler(res.data.id, `/countingsetups/view/${res.data.id}`, `/countingsetups`, props.history)
                }, 2000)
            }).catch(err => {
                setSubmitting(false)
                setStatus(err.error ?? "Error while trying to update the countingsetup")
            })
        } else {
            setSubmitting(false)
            props.history.push(`/countingsetups/view/${props.countingsetup.id}`)
        }
    }
})(innerForm)

export default CountingSetupsEditPageForm
