import React from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {deleteNode, useNode} from '../../../hooks/nodes'
import {CenteredError} from '../../../styles/shared'
import Loading from '../../../components/loading/Loading'
import ActionLink from '../../../components/buttons/action/ActionLink'
import {Pencil, Trash} from '@styled-icons/boxicons-regular'
import HeaderComponent from '../../../components/header/Header'
import ContentComponent from '../../../components/content/Content'
import NodeAdminList from '../../../components/node/NodeAdminList/NodeAdminList'
import {isUndefined} from '../../../helpers/helpers'
import ActionLinkButton from '../../../components/buttons/action/ActionLinkButton'
import {useMutation} from 'react-query'
import {AxiosResponse} from 'axios'

interface Params {
    id: string
}

const NodesViewPage = () => {
    const { id } = useParams<Params>()

    const { data, error, isLoading, } = useNode(id)
    const deleteMutation = useMutation<AxiosResponse<boolean>, Error, string>(deleteNode, {})

    const history = useHistory()

    const onDelete = (id: string) => {
        if (window.confirm("Are you sure you want to delete this node?")) {
            deleteMutation.mutateAsync(id)
                .then(() => history.push(`/nodes`))
                .catch(() => history.push(`/nodes`))
        }
    }

    if (isLoading) return <CenteredError><Loading/></CenteredError>
    if (error) return <CenteredError>Error occurred while fetching: {error.message}</CenteredError>
    if (data == null || isUndefined(data?.id)) return <CenteredError>Data is undefined</CenteredError>

    return (
        <>
            <HeaderComponent title={data.display_name} subtitle={data.description}>
                <ActionLink to={`/nodes/edit/${data.id}`} icon={<Pencil/>}>
                    Edit
                </ActionLink>
                <ActionLinkButton onClick={() => onDelete(data.id)} icon={<Trash/>}>
                    Delete
                </ActionLinkButton>
            </HeaderComponent>
            <ContentComponent>
                <div className={"flex mb-4 flex-wrap"}>
                    <div className={"w-full xl:w-1/2 pr-2"}>
                        <NodeAdminList node_admins={data.node_admins}/>
                    </div>
                    <div className={"w-full xl:w-1/2 pl-2"}>

                    </div>
                </div>
            </ContentComponent>
        </>
    )
}

export default NodesViewPage
