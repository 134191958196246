import React from 'react'
import {AddToQueue} from '@styled-icons/boxicons-regular'
import HeaderComponent from '../../../components/header/Header'
import ContentComponent from '../../../components/content/Content'
import CountingSetupList from '../../../components/countingsetup/CountingSetupList/CountingSetupList'
import {spaceSelector, SpaceSelectorDialog} from '../../../components/modals/SpaceSelector/SpaceSelector'
import {useHistory} from 'react-router-dom'
import ActionLinkButton from '../../../components/buttons/action/ActionLinkButton'
import SearchList from '../../../components/search/SearchList'

const CountingSetupsPage = () => {
    const history = useHistory()
    const [name, setName] = React.useState<string>('')

    const onNewClick = async () => {
        await spaceSelector(SpaceSelectorDialog, {})
            .then(res => {
                if (res !== null) history.push(`/countingsetups/spaces/${res}/new`)
            })
            .catch(err => {
                console.log(err)
            })
    }

    return(
        <>
            <HeaderComponent title={"CountingSetups"} subtitleComponent={<SearchList value={name} action={setName}/>}>
                <ActionLinkButton onClick={onNewClick} icon={<AddToQueue/>}>
                    New
                </ActionLinkButton>
            </HeaderComponent>
            <ContentComponent>
                <CountingSetupList name={name} title={false}/>
            </ContentComponent>
        </>
    )
}

export default CountingSetupsPage
