import React, {useEffect} from 'react'
import {ActionTd, CenteredError, EditTh, Table, Tbody, Td, Th, Thead} from '../../../styles/shared'
import Loading from '../../loading/Loading'
import styled from 'styled-components'
import generateID from "../../../helpers/id"
import dayjs from 'dayjs'
import {Link} from 'react-router-dom'
import ShadowTable from '../../tables/ShadowTable/ShadowTable'
import {Workspace} from '../../../models/workspace'
import axios from 'axios'
import {useQuery} from 'react-query'
import Pagination from '../../buttons/pagination/Pagination'

interface IProps {
    prefix?: string
    requestUrl?: string
    name?: string
}

interface Result {
    count: number
    items: ResultItem[]
}

interface ResultItem extends Workspace {
    counting_setup_count: number
}

const ClientsList = ({ prefix='/clients/view', name = '', requestUrl='/api/v1/workspaces/' }: IProps) => {
    const [page, setPage] = React.useState(0)

    const request = (page: number, name: string) => {
        return axios.get(`${requestUrl}?_start=${page === 0 ? 0 : page*25}&_end=${(page+1)*25}&_name=${name}`).then(res => res.data).catch(err => err.response.data)
    }

    const { data, error, isLoading, isPreviousData, isFetching } = useQuery<Result, Error>(['workspaces', page, name], () => request(page, name), { keepPreviousData: true })

    useEffect(() => {
        setPage(0)
    }, [name])

    if (isLoading || isFetching) return <CenteredError><Loading/></CenteredError>
    if (error) return <CenteredError>Error occurred while fetching: {error.message}</CenteredError>
    if (data == null || data?.items == null) return <CenteredError>Data is undefined</CenteredError>
    if (data.items.length === 0) return <CenteredError>No Clients found</CenteredError>

    return (
        <Wrapper>
            <ShadowTable>
                <Table>
                    <Thead>
                        <tr>
                            <Th scope={"col"}>Name</Th>
                            <Th scope={"col"}>Number of CountingSetups</Th>
                            <Th scope={"col"}>Created</Th>
                            <Th scope={"col"}>Updated</Th>
                            <EditTh scope={"col"} className={"relative px-6 py-3"}>
                                <span className={"sr-only"}>View</span>
                            </EditTh>
                        </tr>
                    </Thead>
                    <Tbody>
                        {
                            data.items.map((value, index) => (
                                <tr key={generateID({page: 'clients-list-item', slug: value.id, index: index})}>
                                    <Td>{value.name}</Td>
                                    <Td>{value.counting_setup_count}</Td>
                                    <Td>{dayjs(value.created_at).fromNow()}</Td>
                                    <Td>{dayjs(value.updated_at).fromNow()}</Td>
                                    <ActionTd>
                                        <Link to={`${prefix}/${value.id}`} className={"text-blue-500 hover:text-blue-700"}>View</Link>
                                    </ActionTd>
                                </tr>
                            ))
                        }
                    </Tbody>
                </Table>
            </ShadowTable>
            <Pagination count={data.count} page={page} action={setPage} isPreviousData={isPreviousData}/>
        </Wrapper>
    )
}

const Wrapper = styled.div.attrs({
    className: "flex flex-col mt-3"
})``

export default ClientsList
