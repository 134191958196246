import {useQuery, UseQueryResult} from 'react-query'
import {Space, GetSpace} from '../models/space'
import axios, {AxiosPromise} from 'axios'

export function useSpaces(): UseQueryResult<Space[], Error> {
    return useQuery<Space[], Error>('spaces', fetchSpaces)
}

function fetchSpaces(): Promise<Space[]> {
    return axios.get('/api/v1/spaces/').then(res => res.data).catch(err => err.response.data)
}

export function useSpace(id: string): UseQueryResult<GetSpace, Error> {
    return useQuery<GetSpace, Error>(`space.${id}`, () => fetchSpace(id))
}

function fetchSpace(id: string): Promise<GetSpace> {
    return axios.get(`/api/v1/spaces/${id}`).then(res => res.data).catch(err => err.response.data)
}

export function patchSpace(id: string, data: object): AxiosPromise<Space> {
    return axios.patch(`/api/v1/spaces/${id}`, data)
}

export interface CreateSpaceInput {
    name: string
    description: string
    notes: string[]
    workspace_id: string
    location: {
        lat: number
        lon: number
    }
}

export function createSpace(data: CreateSpaceInput): AxiosPromise<Space> {
    return axios.post('/api/v1/spaces/', data)
}
