import React from 'react'
import {UseMutationResult} from 'react-query'
import {GetNode, keyable} from '../../../models/node'
import {NodeType} from '../../../hooks/nodes'
import { History } from 'history'
import {FormikProps, withFormik} from 'formik'
import * as Yup from 'yup'
import {
    ErrorItem,
    ErrorWrapper,
    FormButton,
    FormGroup,
    FormInput,
    FormLabel, FormSelect,
    FormTextarea
} from '../../../styles/shared'
import generateID from '../../../helpers/id'
import {redirectHandler} from '../../../helpers/handlers'
import {AxiosResponse} from 'axios'

interface FormValues {
    description: string
    uid: string
    node_type: string
}

interface FormProps {
    mutation: UseMutationResult<AxiosResponse<GetNode>, Error, object, unknown>
    history: History
    node: GetNode
}

const innerForm: React.FC<FormProps & FormikProps<FormValues>> = (props) => {
    return(
        <form className={"mt-5"} onSubmit={props.handleSubmit}>
            {
                props.status != null
                &&
                <div className={"shadow-none my-3 p-2 bg-green-300 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"} role="alert">
                    <span className={"flex rounded-full bg-green-600 text-white uppercase px-2 py-1 text-xs font-bold mr-3"}>Status</span>
                    <span className={"font-semibold text-white mr-2 text-left flex-auto"}>{props.status}</span>
                </div>
            }
            <FormGroup>
                <FormLabel htmlFor={"uid"}>Uid</FormLabel>
                <FormInput onChange={props.handleChange} onBlur={props.handleBlur} type={"string"} name={"uid"} id={"uid"} value={props.values.uid ?? ""}/>
                <ErrorWrapper>
                    {
                        props.errors.uid && props.touched.uid && <ErrorItem>{props.errors.uid}</ErrorItem>
                    }
                </ErrorWrapper>
            </FormGroup>
            <FormGroup>
                <FormLabel htmlFor={"description"}>Description</FormLabel>
                <FormTextarea onChange={props.handleChange} onBlur={props.handleBlur} name={"description"} id={"description"} value={props.values.description}/>
                <ErrorWrapper>
                    {
                        props.errors.description && props.touched.description && <ErrorItem>{props.errors.description}</ErrorItem>
                    }
                </ErrorWrapper>
            </FormGroup>
            <FormGroup>
                <FormLabel htmlFor={"node_type"}>Node Type</FormLabel>
                <FormSelect onChange={props.handleChange} onBlur={props.handleBlur} name={"node_type"} id={"node_type"} value={props.values.node_type}>
                    {
                        Object.values(NodeType).map((value, index) => (
                            <option key={generateID({page: "nodes-new-node-type", slug: value, index: index})} value={value}>{value}</option>
                        ))
                    }
                    <option value={""} disabled hidden>Select a node type</option>
                </FormSelect>
                <ErrorWrapper>
                    {
                        props.errors.node_type && props.touched.node_type && <ErrorItem>{props.errors.node_type}</ErrorItem>
                    }
                </ErrorWrapper>
            </FormGroup>
            <FormButton type={"submit"} disabled={
                props.isSubmitting ||
                !!(props.errors.node_type && props.touched.node_type)
            }>
                Submit
            </FormButton>
        </form>
    )
}

const getNodeType = (config: keyable|null): string => {
    if (config === null) return ""
    if ("node_type" in config) return config["node_type"][0] ?? ""
    return ""
}

const NodesEditPageForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => ({
        description: props.node.description ?? "",
        uid: props.node.uid.toString() ?? "",
        node_type: getNodeType(props.node.config)
    }),
    validationSchema: Yup.object().shape({
        description: Yup.string()
            .notRequired(),
        node_type: Yup.string()
            .required("Name is required"),
        uid: Yup.string()
            .required("Uid is required"),
    }),
    handleSubmit: (values, {props, setSubmitting, setStatus}) => {
        setStatus(undefined)
        if (values.uid === null) return

        const parsed = parseInt(values.uid)
        if (isNaN(parsed)) return

        const data: keyable = {
            description: values.description,
            uid: parsed,
            node_type: values.node_type
        }

        if (data.description === props.node.description) delete data['description']
        if (data.uid === props.node.uid) delete data['uid']
        if (data.node_type === getNodeType(props.node.config)) delete data['node_type']

        if (Object.keys(data).length > 0) {
            props.mutation.mutateAsync(data).then(res => {
                setSubmitting(false)
                setStatus("Successfully updated the node")
                setTimeout(() => {
                    redirectHandler(res.data.id, `/nodes/view/${res.data.id}`, `/nodes`, props.history)
                }, 2000)
            }).catch(err => {
                setSubmitting(false)
                setStatus(err.error ?? "Error while trying to update the node")
            })
        } else {
            setSubmitting(false)
            setTimeout(() => {
                props.history.push(`/nodes/view/${props.node.id}`)
            }, 2000)
        }
    }
})(innerForm)

export default NodesEditPageForm
