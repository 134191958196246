import {useQuery, UseQueryResult} from 'react-query'
import axios from 'axios'

export function useSubdomains(): UseQueryResult<string[], Error> {
    return useQuery<string[], Error>('subdomains', fetchSubdomains)
}

function fetchSubdomains(): Promise<string[]> {
    return axios.get('/api/v1/subdomains/').then(res => res.data).catch(err => err.response.data)
}
