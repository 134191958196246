import React from 'react'
import HeaderComponent from '../../../components/header/Header'
import ContentComponent from '../../../components/content/Content'
import {useSpaces} from '../../../hooks/spaces'
import {useMutation} from 'react-query'
import {GetNode} from '../../../models/node'
import {createNode, CreateNodeInput} from '../../../hooks/nodes'
import {useHistory} from 'react-router-dom'
import {CenteredError} from '../../../styles/shared'
import Loading from '../../../components/loading/Loading'
import NodesNewPageForm from './NodesNewPageForm'
import {AxiosResponse} from 'axios'

const NodesNewPage = () => {
    const { data, error, isLoading } = useSpaces()

    const mutation = useMutation<AxiosResponse<GetNode>, Error, CreateNodeInput>(createNode, {})
    const history = useHistory()

    if (isLoading) return <CenteredError><Loading/></CenteredError>
    if (error) return <CenteredError>Error occurred while fetching: {error.message}</CenteredError>
    if (data == null) return <CenteredError>Data is undefined</CenteredError>

    return(
        <>
            <HeaderComponent title={"New Node"}/>
            <ContentComponent>
                <NodesNewPageForm mutation={mutation} history={history} spaces={data}/>
            </ContentComponent>
        </>
    )
}

export default NodesNewPage
