import React from 'react'
import styled from 'styled-components'
import {useHistory} from "react-router-dom";

interface Props {
    title: string | null
    subtitle?: string | null
    subtitleComponent?: React.ReactNode
}


const HeaderComponent = ({title, subtitle, subtitleComponent, children}: React.PropsWithChildren<Props>) => {
    const history = useHistory();
    return (
        <Container>
            <div className={"lg:flex lg:items-center lg:justify-between w-full"}>
                <div className={"flex-1 min-w-0"}>
                    {
                        history && (
                            <BackIconContainer xmlns="http://www.w3.org/2000/svg" width="50" height="50"
                                               viewBox="0 0 512 512"
                                               onClick={() => history.goBack()}>
                                <title>Previous page</title>
                                <BackIconPartOne points="244 400 100 256 244 112"/>
                                <BackIconPartTwo x1="120" y1="256" x2="412" y2="256"/>
                            </BackIconContainer>
                        )
                    }
                    <Title>
                        {title ?? ""}
                    </Title>
                    {
                        subtitle != null
                        &&
                        <div className={"mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"}>
                            <div className={"mt-2 flex items-center text-sm text-gray-500"}>
                                {subtitle}
                            </div>
                        </div>
                    }
                    {
                        subtitleComponent !== null
                        &&
                        subtitleComponent
                    }
                </div>
                <div className={"mt-5 flex lg:mt-0 lg:ml-4"}>
                    {children}
                </div>
            </div>
        </Container>
    )
}


const Container = styled.div.attrs({
    className: "text-3xl font-extrabold p-5 text-gray-900"
})`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #e6e3e4;
  min-height: 80px;
`

const Title = styled.h2.attrs({
    className: "text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate"
})``

const BackIconPartOne = styled.polyline.attrs({})`
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 48px;
`

const BackIconPartTwo = styled.line.attrs({})`
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 48px;
`

const BackIconContainer = styled.svg.attrs({})`
  cursor: pointer;
  float: left;
  height: 25px;
  margin-top: 6px;
`

export default HeaderComponent
