import {useQuery} from 'react-query'
import {Workspace} from '../models/workspace'
import axios, {AxiosPromise} from 'axios'

export function useWorkspace(id: string, requestUrl?: string) {
    return useQuery<Workspace, Error>(`workspace.${id}`, () => fetchWorkspace(id, requestUrl))
}

export function fetchWorkspace(id: string, requestUrl?: string): Promise<Workspace> {
    return axios.get(`${requestUrl ?? '/api/v1/workspaces/'}${id}`).then(res => res.data).catch(err => err.response.data)
}

export interface CreateWorkspaceInput {
    name: string
    description: string
    notes: string[]
    location: {
        latitude: number
        longitude: number
    }
    subdomain: string
}

export function createWorkspace(data: CreateWorkspaceInput): AxiosPromise<Workspace> {
    return axios.post('/api/v1/workspaces/', data)
}
