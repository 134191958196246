import React from 'react'
import {CenteredError} from '../../../styles/shared'
import ClientsNewPageForm from './ClientsNewPageForm'
import {useSubdomains} from '../../../hooks/subdomains'
import {useMutation} from 'react-query'
import {Workspace} from '../../../models/workspace'
import {createWorkspace, CreateWorkspaceInput} from '../../../hooks/workspaces'
import {useHistory} from 'react-router-dom'
import Loading from '../../../components/loading/Loading'
import HeaderComponent from '../../../components/header/Header'
import ContentComponent from '../../../components/content/Content'
import {AxiosResponse} from 'axios'

const ClientsNewPage = () => {
    const { data, error, isLoading } = useSubdomains()
    const mutation = useMutation<AxiosResponse<Workspace>, Error, CreateWorkspaceInput>(createWorkspace, {})
    const history = useHistory()

    if (isLoading) return <CenteredError><Loading/></CenteredError>
    if (error) return <CenteredError>Error occurred while fetching: {error.message}</CenteredError>
    if (data == null) return <CenteredError>Data is undefined</CenteredError>

    return(
        <>
            <HeaderComponent title={"New Client"}/>
            <ContentComponent>
                <ClientsNewPageForm mutation={mutation} history={history} subdomains={data}/>
            </ContentComponent>
        </>
    )
}

export default ClientsNewPage
