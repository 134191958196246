import React, {PropsWithChildren, useState} from 'react'
import { createPortal } from 'react-dom'
import {generateUUID} from '../../helpers/helpers'
import styled from 'styled-components'
import Toast from './toast'

interface ToastItem {
    content: string;
    type: ToastType;
    duration?: number;
    action?: {
        action: () => void;
        text: string;
    };
}

export interface ToastRawItem {
    content: string;
    type: ToastType;
    duration?: number;
    action?: {
        action: () => void;
        text: string;
    };
    uuid: string;
}

type ToastType = "SUCCESS" | "INFO" | "ERROR";

export interface ToastInterface {
    addToast: (toast: ToastItem) => string;
    toasts: ToastItem[];
    deleteToast: (toast: string) => void;
}

export type WithToastProps = {
    toast: ToastInterface;
}

const ctxt = React.createContext<ToastInterface | null>(null)

ctxt.displayName = "ToastContext"

export const ToastContextProvider = ctxt.Provider
export const ToastContextConsumer = ctxt.Consumer

const ToastContextWrapper = ({ children }: PropsWithChildren<any>) => {
    const [toasts, setToasts] = useState<ToastRawItem[]>([])

    const addToast = (toast: ToastItem) => {
        let uuid = generateUUID()
        setToasts(prevState => ([...prevState, {...toast, uuid: uuid}]))
        return uuid;
    }

    const deleteToast = (uuid: string) => setToasts(prevState => prevState.filter(value => value.uuid !== uuid))

    return(
        <ToastContextProvider value={{
            toasts,
            addToast,
            deleteToast
        }}>
            {
                createPortal(
                    <Wrapper>
                        <Container>
                            {
                                toasts.map(value => {
                                    return(
                                        <Toast key={value.uuid} item={value} remove={() => deleteToast(value.uuid)}/>
                                    );
                                })
                            }
                        </Container>
                    </Wrapper>,
                    document.body
                )
            }
            {children}
        </ToastContextProvider>
    )
}

export default ToastContextWrapper

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`

const Container = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  //left: 50%;
  //transform: translateX(-50%);
  align-self: flex-end;
  z-index: 1000;
  display: flex;
  flex-direction: column;
`

export function withToast<P extends WithToastProps>(Component: React.ComponentType<P>) {
    return function ToastComponent(props: Pick<P, Exclude<keyof P, keyof WithToastProps>>) {
        return(
            <ToastContextConsumer>
                {(toast) => <Component {...props as P} toast={toast}/>}
            </ToastContextConsumer>
        );
    }
}
