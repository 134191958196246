import React, {SetStateAction} from 'react'
import styled from 'styled-components'
import { ChevronLeft, ChevronRight } from '@styled-icons/boxicons-regular'

interface Props {
    count: number
    page: number
    action: React.Dispatch<SetStateAction<number>>
    isPreviousData: boolean
}

const Pagination = ({ count, page, action, isPreviousData }: Props) => (
    <Wrapper>
        <div>
            <Button
                onClick={() => action(old => Math.max(old - 1, 0))}
                disabled={page === 0}
            >
                <ChevronLeft/>
            </Button>
            <span className={"mx-2 text-sm"}>{page + 1}/{Math.ceil(count/25)}</span>
            <Button
                onClick={() => {
                    if (!isPreviousData && (page+1)*25<count) action(old => old + 1)
                }}
                disabled={isPreviousData || (page+1)*25>count}
            >
                <ChevronRight/>
            </Button>
        </div>
        <div>
            <small>Total: {count}</small>
        </div>
    </Wrapper>
)

const Wrapper = styled.div.attrs({
    className: "flex justify-center flex-col items-center my-4"
})``

const Button = styled.button.attrs({})`
  color: black;
  opacity: 1;
  cursor: pointer;
  
  &:disabled {
    cursor: auto;
    opacity: .4;
  }
  
  & > svg {
    width: 25px;
  }
`

export default Pagination
