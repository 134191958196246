import React, {useEffect, useState} from 'react'
import HeaderComponent from '../../../components/header/Header'
import ContentComponent from "../../../components/content/Content";
import {useHistory, useParams} from "react-router-dom";

interface Params {
    encodedLinkMain: string
    encodedLinkSecondary: string
}


const RedirectPage = () => {
    const { encodedLinkMain, encodedLinkSecondary } = useParams<Params>()
    const linkMain = decodeURIComponent(encodedLinkMain)
    const linkSecondary = decodeURIComponent(encodedLinkSecondary)
    const [secondsLeft, setSecondsLeft] = useState<number>(10);
    const history = useHistory()

    if (secondsLeft <= 0) {
        history.push(linkMain)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setSecondsLeft(secondsLeft-1);
        }, 1000);

        return () => clearTimeout(timer);
    });

    return (
        <>
            <HeaderComponent title="Redirecting..." subtitle={`You'll be redirected in ${secondsLeft} seconds`} />
            <ContentComponent>
                <div className={"grid grid-cols-1 max-w-2xl text-center mt-10"}>
                    <p>Time left: {secondsLeft} seconds before redirect to <b>Create new CountingSetup</b></p>
                </div>
                <div className={"grid grid-cols-2 gap-4 max-w-2xl"}>
                    <button onClick={() => history.push(linkSecondary)} className={"max-w-xs m-4 py-3 px-6 font-semibold rounded-md border bg-gray-100 border-slate-200 text-slate-900 text-center"}>View the data you just created </button>
                    <button onClick={() => history.push(linkMain)} className={"max-w-xs m-4 py-3 px-6 font-semibold rounded-md bg-black text-white text-center"}>Create new CountingSetup {">"}</button>
                </div>
            </ContentComponent>
        </>
    )
}

export default RedirectPage
