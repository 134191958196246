import React, {useCallback, useEffect} from 'react'
import styled from 'styled-components'
import ReactDOM from 'react-dom'
import HeaderComponent from '../../header/Header'
import ContentComponent from '../../content/Content'
import {CenteredError, Table, Tbody, Td, Th, Thead} from '../../../styles/shared'
import generateID from '../../../helpers/id'
import ShadowTable from '../../tables/ShadowTable/ShadowTable'
import {QueryClientProvider, useQuery} from 'react-query'
import {queryClient} from '../../../config/queryclient'
import axios from 'axios'
import Loading from '../../loading/Loading'
import env from '../../../production.env'
import { Point } from 'geojson'

const dialogNode = document.getElementById('search-dialog-node')

interface Props {}

interface Location {
    latitude: number
    longitude: number
}

interface LocationResponse {
    name: string|null
    location: Location
}

export const searchLocation = (
    DialogContent: (props: {
        response: (answer: LocationResponse|null) => void,
        content: Props
    }) => React.ReactElement,
    props: Props
): Promise<LocationResponse|null> =>
    new Promise<LocationResponse|null>(res => {
        if (dialogNode == null) return
        const giveAnswer = (answer: LocationResponse|null) => {
            ReactDOM.unmountComponentAtNode(dialogNode)
            res(answer)
        }

        ReactDOM.render(<QueryClientProvider client={queryClient}><DialogContent response={giveAnswer} content={props}/></QueryClientProvider> , dialogNode)
    })

interface ComponentProps {
    response: (answer: LocationResponse|null) => void
    content: Props
}

interface Feature {
    id: string
    type: string
    relevance: number
    properties: {
        [key: string]: any
    }
    text: string
    place_name: string
    geometry: Point
}

interface Result {
    type: string
    query: string[]
    features: Feature[]
    attribution: string
}

export const SearchLocationDialog = ({ response, content }: ComponentProps) => {
    const [search, setSearch] = React.useState<string>('')

    const request = (search: string) => {
        return axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(search)}.json?access_token=${env.mapBoxToken}&country=nl,be,de`).then(res => res.data).catch(err => err.response.data)
    }

    const { data, error, isLoading, refetch } = useQuery<Result, Error>(`mapbox.${search}`, () => request(search), {enabled: false})

    const ready = useCallback(() => search.length > 2, [search])

    useEffect(() => {
        if (ready()) refetch()
    }, [ready, refetch, search])

    const onClick = (f: Feature) => response({location: {latitude: f.geometry.coordinates[1], longitude: f.geometry.coordinates[0]}, name: f.text})

    return(
        <Modal>
            <ModalBg onClick={() => response(null)}/>
            <ModalContent>
                <HeaderComponent title={"Search Location"}>
                    <SearchInput placeholder={"Start typing...."} autoFocus={true} value={search} type={"text"} onChange={(e) => setSearch(e.target.value)}/>
                </HeaderComponent>
                <ContentComponent>
                    {
                        isLoading ? (
                            <CenteredError><Loading/></CenteredError>
                        ) : (
                            ready() && error ? (
                                <CenteredError>Error occurred while fetching: {error.message}</CenteredError>
                            ) : (
                                ready() && (data == null || data?.features == null || data?.features.length === 0) ? (
                                    <CenteredError>Data is undefined</CenteredError>
                                ) : (
                                    <Wrapper>
                                        <ShadowTable>
                                            <Table>
                                                <Thead>
                                                    <tr>
                                                        <Th scope={"col"}>Name</Th>
                                                        <Th scope={"col"}>Full Name</Th>
                                                        <Th scope={"col"}>Coordinates</Th>
                                                    </tr>
                                                </Thead>
                                                <Tbody>
                                                    {
                                                        data != null && data.features.map((value, index) => (
                                                            <tr onClick={() => onClick(value)} className={"cursor-pointer bg-transparent transition-all hover:bg-gray-200"} key={generateID({page: 'saerch-location-item', slug: value.id?.toString() ?? "", index: index})}>
                                                                <Td>{value.text}</Td>
                                                                <Td>{value.place_name}</Td>
                                                                <Td>{value.geometry.coordinates[1]}, {value.geometry.coordinates[0]}</Td>
                                                            </tr>
                                                        ))
                                                    }
                                                </Tbody>
                                            </Table>
                                        </ShadowTable>
                                    </Wrapper>
                                )
                            )
                        )
                    }
                </ContentComponent>
            </ModalContent>
        </Modal>
    )
}

const Wrapper = styled.div.attrs({
    className: "flex flex-col mt-3 mb-3"
})``

const Modal = styled.div`
  position: fixed;
  z-index: 10010;
`

const SearchInput = styled.input``

const ModalBg = styled.div`
  background: rgba(70, 75, 79, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
`

const ModalContent = styled.div`
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px;
  pointer-events: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  min-width: 1000px;
  height: auto;
  min-height: 300px;
  transition: .2s height;
`
