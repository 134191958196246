import React from 'react'
import {useParams} from 'react-router-dom'
import {CenteredError} from '../../../styles/shared'
import {useMutation} from 'react-query'
import Loading from '../../../components/loading/Loading'
import ActionLink from "../../../components/buttons/action/ActionLink"
import {Pencil} from '@styled-icons/boxicons-regular'
import HeaderComponent from '../../../components/header/Header'
import ContentComponent from '../../../components/content/Content'
import {patchSpace, useSpace} from '../../../hooks/spaces'
import {Space} from '../../../models/space'
import NotesList from '../../../components/notes/NotesList/NotesList'
import {isUndefined} from '../../../helpers/helpers'
import {AxiosResponse} from 'axios'

interface Params {
    id: string
}

const SpacesViewPage = () => {
    const { id } = useParams<Params>()

    const { data, error, isLoading, refetch } = useSpace(id)
    const mutation = useMutation<AxiosResponse<Space>, Error, object>((pData) => patchSpace(id, pData), {})

    if (isLoading) return <CenteredError><Loading/></CenteredError>
    if (error) return <CenteredError>Error occurred while fetching: {error.message}</CenteredError>
    if (data == null || isUndefined(data?.id)) return <CenteredError>Data is undefined</CenteredError>

    return(
        <>
            <HeaderComponent title={data.display_name} subtitle={data.description}>
                {
                    data.workspace !== null
                    &&
                    <ActionLink to={`/clients/view/${data.workspace.id}`} icon={<Pencil/>}>
                        Workspace
                    </ActionLink>
                }
                <ActionLink to={`/spaces/edit/${data.id}`} icon={<Pencil/>}>
                    Edit
                </ActionLink>
            </HeaderComponent>
            <ContentComponent>
                <NotesList notes={data.notes} mutation={mutation} refetch={refetch}/>
            </ContentComponent>
        </>
    )
}

export default SpacesViewPage
