import React from 'react'
import SpacesNewPageForm from './SpacesNewPageForm'
import {useMutation} from 'react-query'
import {useHistory} from 'react-router-dom'
import HeaderComponent from '../../../components/header/Header'
import ContentComponent from '../../../components/content/Content'
import {createSpace, CreateSpaceInput} from '../../../hooks/spaces'
import {Space} from '../../../models/space'
import {AxiosResponse} from 'axios'

const SpacesNewPage = () => {
    const mutation = useMutation<AxiosResponse<Space>, Error, CreateSpaceInput>(createSpace, {})
    const history = useHistory()

    return(
        <>
            <HeaderComponent title={"New Space"}/>
            <ContentComponent>
                <SpacesNewPageForm mutation={mutation} history={history}/>
            </ContentComponent>
        </>
    )
}

export default SpacesNewPage
