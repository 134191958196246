import React from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {deleteCountingSetup, patchCountingSetup, useCountingSetup} from '../../../hooks/countingsetups'
import {CenteredError} from '../../../styles/shared'
import {useMutation} from 'react-query'
import Loading from '../../../components/loading/Loading'
import ActionLink from "../../../components/buttons/action/ActionLink"
import {Pencil} from '@styled-icons/boxicons-regular'
import HeaderComponent from '../../../components/header/Header'
import ContentComponent from '../../../components/content/Content'
import {GetCountingSetup} from '../../../models/groups'
import CounterList from '../../../components/counter/CounterList/CounterList'
import GatewayList from '../../../components/gateway/GatewayList/GatewayList'
import NodeAdminList from '../../../components/node/NodeAdminList/NodeAdminList'
import NotesList from '../../../components/notes/NotesList/NotesList'
import { AddToQueue, Trash } from '@styled-icons/boxicons-regular'
import {isUndefined} from '../../../helpers/helpers'
import ActionLinkButton from '../../../components/buttons/action/ActionLinkButton'
import {AxiosResponse} from 'axios'
import SingleMapInfoView from "../../../components/map/SingleMapInfoView/SingleMapInfoView";
import {MultiPointField} from "../../../models/location";

interface Params {
    id: string
}

const CountingSetupsViewPage = () => {
    const { id } = useParams<Params>()

    const { data, error, isLoading, refetch } = useCountingSetup(id)
    const mutation = useMutation<AxiosResponse<GetCountingSetup>, Error, object>((pData) => patchCountingSetup(id, pData), {})
    const deleteMutation = useMutation<AxiosResponse<boolean>, Error, string>(deleteCountingSetup, {})

    const history = useHistory()

    const onDelete = (id: string) => {
        if (window.confirm("Are you sure you want to delete this counting setup?")) {
            deleteMutation.mutateAsync(id)
                .then(() => history.goBack())
                .catch(() => history.goBack())
        }
    }

    if (isLoading) return <CenteredError><Loading/></CenteredError>
    if (error) return <CenteredError>Error occurred while fetching: {error.message}</CenteredError>
    if (data == null || isUndefined(data?.id)) return <CenteredError>Data is undefined</CenteredError>

    return(
        <>
            <HeaderComponent title={data.display_name} subtitle={data.description}>
                {
                    (data.counter_node_admins.filter(value => value.node_admin.end_date === null).length > 0 && data.gateway_node_admins.filter(value => value.node_admin.end_date === null).length > 0)
                    ?
                    <ActionLink to={`/countingsetups/edit-install/${data.id}`} icon={<Pencil/>}>
                        Edit install
                    </ActionLink>
                    :
                    <ActionLink to={`/countingsetups/install/${data.id}`} icon={<AddToQueue/>}>
                        Install
                    </ActionLink>
                }
                <ActionLink to={`/countingsetups/edit/${data.id}`} icon={<Pencil/>}>
                    Edit
                </ActionLink>
                <ActionLinkButton onClick={() => onDelete(data.id)} icon={<Trash/>}>
                    Delete
                </ActionLinkButton>
            </HeaderComponent>
            <ContentComponent>
                <div className={"mb-4 h-96"}>
                    <SingleMapInfoView name={data.display_name} location={data.location as MultiPointField} />
                </div>
                <div className={"flex mb-4 flex-wrap"}>
                    <div className={"w-full xl:w-1/2 pr-2"}>
                        <CounterList counters={data.counters} name_positive={data.name_positive} name_negative={data.name_negative}/>
                    </div>
                    <div className={"w-full xl:w-1/2 pl-2"}>
                        <GatewayList gateways={data.gateways}/>
                    </div>
                </div>
                <div className={"flex mb-4 flex-wrap"}>
                    <div className={"w-full xl:w-1/2 pr-2"}>
                        <NodeAdminList node_admins={data.counter_node_admins} title={"Counter Node Admins"} deleteCallback={() => refetch()}/>
                    </div>
                    <div className={"w-full xl:w-1/2 pl-2"}>
                        <NodeAdminList node_admins={data.gateway_node_admins} title={"Gateway Node Admins"} deleteCallback={() => refetch()}/>
                    </div>
                </div>
                <NotesList notes={data.notes} mutation={mutation} refetch={refetch}/>
            </ContentComponent>
        </>
    )
}

export default CountingSetupsViewPage
