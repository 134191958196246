import React from 'react'
import styled from 'styled-components'
import ReactDOM from 'react-dom'
import HeaderComponent from '../../header/Header'
import ContentComponent from '../../content/Content'
import {CenteredError, Table, Tbody, Td, Th, Thead} from '../../../styles/shared'
import generateID from '../../../helpers/id'
import dayjs from 'dayjs'
import ShadowTable from '../../tables/ShadowTable/ShadowTable'
import {QueryClientProvider, useQuery} from 'react-query'
import {queryClient} from '../../../config/queryclient'
import axios from 'axios'
import Loading from '../../loading/Loading'
import Pagination from '../../buttons/pagination/Pagination'
import {Space} from '../../../models/space'
import Search from '../../search/Search'

const dialogNode = document.getElementById('dialog-node')

interface Props {}

export const spaceSelector = (
    DialogContent: (props: {
        response: (answer: string|null) => void,
        content: Props
    }) => React.ReactElement,
    props: Props
): Promise<string|null> =>
    new Promise<string|null>(res => {
        if (dialogNode == null) return
        const giveAnswer = (answer: string|null) => {
            ReactDOM.unmountComponentAtNode(dialogNode)
            res(answer)
        }

        ReactDOM.render(<QueryClientProvider client={queryClient}><DialogContent response={giveAnswer} content={props}/></QueryClientProvider> , dialogNode)
    })

interface ComponentProps {
    response: (answer: string|null) => void
    content: Props
}

interface Result {
    count: number
    items: Space[]
}

const ContentWrapper = ({ response, children }: React.PropsWithChildren<ComponentProps>) => (
    <Modal>
        <ModalBg onClick={() => response(null)}/>
        <ModalContent>
            {children}
        </ModalContent>
    </Modal>
)

export const SpaceSelectorDialog = ({ response, content }: ComponentProps) => {
    const [page, setPage] = React.useState(0)
    const [search, setSearch] = React.useState("")

    const request = (page: number, search: string) => axios.get(`/api/v1/spaces/?_start=${page === 0 ? 0 : page*25}&_end=${(page+1)*25}${search.length > 0 ? `&_name=${search}` : ``}`).then(res => res.data).catch(err => err.response.data)

    const { data, error, isLoading, isPreviousData, isFetching } = useQuery<Result, Error>([`spaces`, page, search], () => request(page, search), { keepPreviousData: true })

    return(
        <ContentWrapper response={response} content={content}>
            <HeaderComponent title={"Space Selector"}>
                <Search value={search} action={setSearch} placeholder={"Search on name"}/>
            </HeaderComponent>
            <ContentComponent>
                {
                    (isLoading || isFetching) ?
                        (
                            <CenteredError><Loading/></CenteredError>
                        ) : (
                            error ? (
                                <CenteredError>Error occurred while fetching: {error.message}</CenteredError>
                            ) : (
                                (data == null || data?.items == null || data?.items.length === 0) ? (
                                    <CenteredError>Data is undefined</CenteredError>
                                ) : (
                                    <Wrapper>
                                        <ShadowTable>
                                            <Table>
                                                <Thead>
                                                    <tr>
                                                        <Th scope={"col"}>ID</Th>
                                                        <Th scope={"col"}>Name</Th>
                                                        <Th scope={"col"}>Created</Th>
                                                        <Th scope={"col"}>Updated</Th>
                                                    </tr>
                                                </Thead>
                                                <Tbody>
                                                    {
                                                        data.items.map((value, index) => (
                                                            <tr onClick={() => response(value.id)} className={"cursor-pointer bg-transparent transition-all hover:bg-gray-200"} key={generateID({page: 'space-selector-list-item', slug: value.id, index: index})}>
                                                                <Td>{value.id}</Td>
                                                                <Td>{value.display_name}</Td>
                                                                <Td>{dayjs(value.created_at).fromNow()}</Td>
                                                                <Td>{dayjs(value.updated_at).fromNow()}</Td>
                                                            </tr>
                                                        ))
                                                    }
                                                </Tbody>
                                            </Table>
                                        </ShadowTable>
                                        <Pagination count={data.count} page={page} action={setPage} isPreviousData={isPreviousData}/>
                                    </Wrapper>
                                )
                            )
                        )
                }
            </ContentComponent>
        </ContentWrapper>
    )
}

const Wrapper = styled.div.attrs({
    className: "flex flex-col mt-3"
})``


const Modal = styled.div`
  position: fixed;
  z-index: 10010;
`

const ModalBg = styled.div`
  background: rgba(70, 75, 79, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
`

const ModalContent = styled.div`
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px;
  pointer-events: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 1000px;
  height: 750px;
`
