import React, {SetStateAction} from 'react'
import styled from 'styled-components'

interface Props {
    value: string
    action: React.Dispatch<SetStateAction<string>>
    placeholder?: string
}

const Search = ({ value, action, placeholder }: Props) => (
    <Wrapper>
        <SearchInput placeholder={placeholder} value={value} type={"text"} onChange={(e) => action(e.target.value)}/>
    </Wrapper>
)

const Wrapper = styled.div.attrs({
    className: "flex justify-center flex-col items-center my-4"
})``

const SearchInput = styled.input.attrs({
    className: "text-xl pb-1"
})`
  appearance: none;
  outline: none;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color .2s ease-in-out;
  
  &:focus, &:hover {
    border-bottom-color: black;
  }
`

export default Search
