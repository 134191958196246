import React, {PropsWithChildren} from 'react'
import styled from 'styled-components'
import {NavLink} from 'react-router-dom'
import {Home5} from '@styled-icons/remix-line'
import {People} from '@styled-icons/bootstrap'
import {MapMarkerAlt} from '@styled-icons/fa-solid'

const DashboardLayout = ({ children }: PropsWithChildren<any>) => (
    <Container>
        <Sidebar>
            <Header>
                Scimitar
            </Header>
            <Menu>
                <Link to={"/"} activeClassName={"active"} exact={true}>
                    <LinkIcon>
                        <Home5/>
                    </LinkIcon>
                    <LinkSpan>
                        Home
                    </LinkSpan>
                </Link>
                <Link to={"/clients"} activeClassName={"active"} exact={false}>
                    <LinkIcon>
                        <People/>
                    </LinkIcon>
                    <LinkSpan>
                        Clients
                    </LinkSpan>
                </Link>
                <Link to={"/countingsetups"} activeClassName={"active"} exact={false}>
                    <LinkIcon>
                        <MapMarkerAlt/>
                    </LinkIcon>
                    <LinkSpan>
                        CountingSetups
                    </LinkSpan>
                </Link>
                <Link to={"/nodes"} activeClassName={"active"} exact={false}>
                    <LinkIcon>
                        <MapMarkerAlt/>
                    </LinkIcon>
                    <LinkSpan>
                        Nodes
                    </LinkSpan>
                </Link>
                <Link to={"/spaces"} activeClassName={"active"} exact={false}>
                    <LinkIcon>
                        <MapMarkerAlt/>
                    </LinkIcon>
                    <LinkSpan>
                        Spaces
                    </LinkSpan>
                </Link>
            </Menu>
        </Sidebar>
        <Main>
            {children}
        </Main>
    </Container>
)

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  background-color: white;
`

const Sidebar = styled.div`
  width: 300px;
  height: 100%;
  //border-right: 2px solid #e4e4e4;
  background-color: #f6f4f5;
`

const Main = styled.div.attrs({
    className: ""
})`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Header = styled.h1.attrs({
    className: "inline-block text-3xl font-bold p-5 mb-5 w-full"
})`
  color: black;
  height: 80px;
  border-bottom: 1px solid #e6e3e4;
`

const Menu = styled.ul.attrs({
    className: "p-5"
})`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const Link = styled(NavLink).attrs({
    className: "py-2 mb-2"
})`
  color: #919399;
  display: flex;
  flex-direction: row;
  align-items: center;
  
  &.active {
    color: black;
  }
`

const LinkIcon = styled.span.attrs({
    className: "pr-4"
})`
  width: 40px;
  display: flex;
  
  overflow: hidden;
  position: relative;
  color: black;
  
  & > svg {
    height: 30px;
    width: auto;
  }
`

const LinkSpan = styled.span.attrs({
    className: "font-medium lg:text-sm"
})``

export default DashboardLayout
