import React from 'react'

interface Props {
    onClick: () => void
    icon: React.ReactElement
}

const ActionLinkButton = ({ onClick, icon, children }: React.PropsWithChildren<Props>) => (
    <span className={"hidden sm:block ml-3"}>
        <button onClick={onClick} className={"inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"}>
            {React.cloneElement(icon, { className: "-ml-1 mr-2 h-5 w-5 text-gray-500" })}
            {children}
        </button>
    </span>
)

export default ActionLinkButton
