import React from 'react'
import {FormikProps, withFormik} from 'formik'
import {GetCountingSetup} from '../../../../models/groups'
import * as Yup from 'yup'
import {
    ErrorItem,
    ErrorWrapper,
    FormButton,
    FormCancelButton,
    FormCheckbox,
    FormGroup,
    FormInline,
    FormInlineColl,
    FormInput,
    FormLabel
} from '../../../../styles/shared'
import styled from 'styled-components'
import generateID from '../../../../helpers/id'
import {SelectMultiple} from '@styled-icons/boxicons-regular'
import {nodeSelector, NodeSelectorDialog} from '../../../../components/modals/NodeSelector/NodeSelector'
import {QueryObserverResult, RefetchOptions, RefetchQueryFilters, UseMutationResult} from 'react-query'
import {History} from 'history'
import {CLSType} from '../../../../models/base'
import {GetNodeAdmin, keyable} from '../../../../models/node'
import {NewNodeAdminInput, PatchPutNodeAdminInput} from '../../../../hooks/nodeadmins'
import dayjs from 'dayjs'
import {AxiosResponse} from "axios"
import {withToast, WithToastProps} from '../../../../components/toast/toastmanager'
import {nodeExists} from '../../../../hooks/nodes';

interface FormValues {
    items: FormValuesItem[]
}

interface FormValuesItem {
    buttons_enabled: false
    counter_id: string
    gateway_id: string|null
    gateway_uid: number|null
    sensor_1_uid: number|null
    distance: number|null
    sensor_2_uid: number|null
}

interface FormProps {
    refetch: (options?: ((RefetchOptions & RefetchQueryFilters<any>) | undefined)) => Promise<QueryObserverResult<any, Error>>
    patchMutation: UseMutationResult<AxiosResponse<GetNodeAdmin>, Error, PatchPutNodeAdminInput, unknown>
    newMutation: UseMutationResult<AxiosResponse<GetNodeAdmin>, Error, NewNodeAdminInput, unknown>
    putMutation: UseMutationResult<AxiosResponse<GetNodeAdmin>, Error, PatchPutNodeAdminInput, unknown>
    deleteMutation: UseMutationResult<AxiosResponse<boolean>, Error, string, unknown>
    history: History
    countingSetup: GetCountingSetup
}

const InnerForm: React.FC<FormProps & FormikProps<FormValues> & WithToastProps> = (props) => {
    const onGatewayClick = async (index: number) => {
        await nodeSelector(NodeSelectorDialog, {node_type: 'GATEWAY', requestUrl: `/api/v1/installer/nodes/`})
            .then(res => {
                if (res !== null) props.setFieldValue(`items.${index}.gateway_uid`, res)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const onSensorClick = async (index: number, item: number) => {
        await nodeSelector(NodeSelectorDialog, {node_type: 'SENSOR', requestUrl: `/api/v1/installer/nodes/`})
            .then(res => {
                if (res !== null) props.setFieldValue(`items.${index}.sensor_${item}_uid`, res)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const validateUIDs = async (): Promise<boolean> => {
        let valid = true
        // Set to valid=false if valid is not already false
        for await (const value of props.values.items) {
            if (value.gateway_uid) {
                const _valid = await nodeExists(value.gateway_uid, true)
                if (!_valid) {
                    props.toast.addToast({
                        content: "Gateway uid is invalid",
                        type: "ERROR",
                        duration: 3000
                    })
                    valid = false
                }
            }
            if (value.sensor_1_uid) {
                const _valid = await nodeExists(value.sensor_1_uid, true)
                if (!_valid) {
                    props.toast.addToast({
                        content: "Sensor 1 uid is invalid",
                        type: "ERROR",
                        duration: 3000
                    })
                    valid = false
                }
            } else {
                props.toast.addToast({
                    content: "Sensor 1 uid is invalid",
                    type: "ERROR",
                    duration: 3000
                })
            }
            if (value.sensor_2_uid) {
                const _valid = await nodeExists(value.sensor_2_uid, true)
                if (!_valid) {
                    props.toast.addToast({
                        content: "Sensor 2 uid is invalid",
                        type: "ERROR",
                        duration: 3000
                    })
                    valid = false
                }
            }
        }

        return valid
    }

    const onUpdateClick = async (index: number) => {
        const valid = await validateUIDs()
        if (!valid) return;

        const cs = props.countingSetup.counters[index]

        // First check gateway
        const gwNa = props.countingSetup.gateway_node_admins.find(value1 => (value1.node_admin.product._cls === CLSType.Gateway && value1.node_admin.product._ref.$id === cs.gateway) && value1.node_admin.end_date === null)
        if (gwNa != null) {
            const data: keyable = {}
            const newGwUid = props.values.items[index].gateway_uid
            if (newGwUid !== null && gwNa.node.uid !== newGwUid) {
                data.product_uid = newGwUid
            }
            if (Object.keys(data).length > 0) {
                await props.patchMutation.mutateAsync({
                    id: gwNa.node_admin.id,
                    data: data
                }).then(() => {
                    props.toast.addToast({
                        content: "Successfully updated the gateway",
                        type: "SUCCESS",
                        duration: 3000
                    })
                }).catch(() => {
                    props.toast.addToast({
                        content: "Error while trying to update the gateway",
                        type: "ERROR",
                        duration: 3000
                    })
                })
            }
        } else {
            const newGwUid = props.values.items[index].gateway_uid
            if (newGwUid !== null && cs.gateway !== null) {
                const data: NewNodeAdminInput = {
                    node_uid: newGwUid,
                    space: props.countingSetup.space,
                    product: {
                        cls: CLSType.Gateway,
                        id: cs.gateway
                    },
                    position: [],
                    orientation: []
                }
                await props.newMutation.mutateAsync(data).then(() => {
                    props.toast.addToast({
                        content: "Successfully created the gateway",
                        type: "SUCCESS",
                        duration: 3000
                    })
                }).catch(() => {
                    props.toast.addToast({
                        content: "Error while trying to create the gateway",
                        type: "ERROR",
                        duration: 3000
                    })
                })
            }
        }

        // Check first cs
        const cs1Na = props.countingSetup.counter_node_admins.find(value1 => (value1.node_admin.product._cls === CLSType.Counter && value1.node_admin.product._ref.$id === cs.id) && value1.node_admin.end_date === null)
        if (cs1Na != null) {
            const data: keyable = {}
            const newCs1Uid = props.values.items[index].sensor_1_uid
            if (newCs1Uid !== null && cs1Na.node.uid !== newCs1Uid) {
                data.product_uid = newCs1Uid
            }
            if (Object.keys(data).length > 0) {
                await props.patchMutation.mutateAsync({
                    id: cs1Na.node_admin.id,
                    data: data
                }).then(() => {
                    props.toast.addToast({
                        content: "Successfully updated the first counting setup",
                        type: "SUCCESS",
                        duration: 3000
                    })
                }).catch(() => {
                    props.toast.addToast({
                        content: "Error while trying to update the first counting setup",
                        type: "ERROR",
                        duration: 3000
                    })
                })
            }
        } else {
            const newCs1Uid = props.values.items[index].sensor_1_uid
            if (newCs1Uid !== null) {
                const data: NewNodeAdminInput = {
                    node_uid: newCs1Uid,
                    space: props.countingSetup.space,
                    product: {
                        cls: CLSType.Counter,
                        id: cs.id
                    },
                    position: [],
                    orientation: []
                }
                await props.newMutation.mutateAsync(data).then(() => {
                    props.toast.addToast({
                        content: "Successfully created the first counting setup",
                        type: "SUCCESS",
                        duration: 3000
                    })
                }).catch(() => {
                    props.toast.addToast({
                        content: "Error while trying to create the first counting setup",
                        type: "ERROR",
                        duration: 3000
                    })
                })
            }
        }

        // Check second cs
        if (props.countingSetup.counter_node_admins.length > 1) {
            const distance = props.countingSetup.counter_node_admins.find(value1 => (value1.node_admin.product._cls === CLSType.Counter && value1.node_admin.product._ref.$id === cs.id) && value1.node_admin.end_date === null && (value1.node_admin.position.length === 3 && value1.node_admin.position[1] !== 0))?.node_admin.position[1]
            const cs2Na = props.countingSetup.counter_node_admins.find(value1 => (value1.node_admin.product._cls === CLSType.Counter && value1.node_admin.product._ref.$id === cs.id) && value1.node_admin.end_date === null && (value1.node_admin.position.length === 3 && value1.node_admin.position[1] !== 0))
            if (cs2Na != null) {
                const newCs2Uid = props.values.items[index].sensor_2_uid
                const newDistance = props.values.items[index].distance
                const data: keyable = {}
                if (newCs2Uid !== null && cs2Na.node.uid !== newCs2Uid) {
                    data.product_uid = newCs2Uid
                }
                if (newDistance !== null && newDistance !== distance) {
                    data.distance = newDistance
                }
                if (Object.keys(data).length > 0) {
                    await props.patchMutation.mutateAsync({
                        id: cs2Na.node_admin.id,
                        data: data
                    }).then(() => {
                        props.toast.addToast({
                            content: "Successfully updated the second counting setup",
                            type: "SUCCESS",
                            duration: 3000
                        })
                    }).catch(() => {
                        props.toast.addToast({
                            content: "Error while trying to update the second counting setup",
                            type: "ERROR",
                            duration: 3000
                        })
                    })
                }
            } else {
                const newCs2Uid = props.values.items[index].sensor_2_uid
                if (newCs2Uid !== null) {
                    const data: NewNodeAdminInput = {
                        node_uid: newCs2Uid,
                        space: props.countingSetup.space,
                        product: {
                            cls: CLSType.Counter,
                            id: cs.id
                        },
                        position: [0, props.values.items[index].distance ?? 0, 0],
                        orientation: []
                    }
                    await props.newMutation.mutateAsync(data).then(() => {
                        props.toast.addToast({
                            content: "Successfully updated the second counting setup",
                            type: "SUCCESS",
                            duration: 3000
                        })
                    }).catch(() => {
                        props.toast.addToast({
                            content: "Error while trying to update the second counting setup",
                            type: "ERROR",
                            duration: 3000
                        })
                    })
                }
            }
        } else {
            const newCs2Uid = props.values.items[index].sensor_2_uid
            if (newCs2Uid !== null) {
                const data: NewNodeAdminInput = {
                    node_uid: newCs2Uid,
                    space: props.countingSetup.space,
                    product: {
                        cls: CLSType.Counter,
                        id: cs.id
                    },
                    position: [0, props.values.items[index].distance ?? 0, 0],
                    orientation: []
                }
                await props.newMutation.mutateAsync(data).then(() => {
                    props.toast.addToast({
                        content: "Successfully created the second counting setup",
                        type: "SUCCESS",
                        duration: 3000
                    })
                }).catch(() => {
                    props.toast.addToast({
                        content: "Error while trying to create the second counting setup",
                        type: "ERROR",
                        duration: 3000
                    })
                })
            }
        }

        await props.refetch()
            .then(() => props.resetForm())
            .catch(() => props.resetForm())
    }

    const onReplaceClick = async (index: number) => {
        const valid = await validateUIDs()
        if (!valid) return;

        const cs = props.countingSetup.counters[index]

        // First check gateway
        const gwNa = props.countingSetup.gateway_node_admins.find(value1 => (value1.node_admin.product._cls === CLSType.Gateway && value1.node_admin.product._ref.$id === cs.gateway) && value1.node_admin.end_date === null)
        if (gwNa != null) {
            const newGwUid = props.values.items[index].gateway_uid
            if (newGwUid !== null) {
                const data: keyable = {
                    product_uid: newGwUid
                }
                await props.putMutation.mutateAsync({
                    id: gwNa.node_admin.id,
                    data: data
                })
            }
        }

        // Check first cs
        const cs1Na = props.countingSetup.counter_node_admins.find(value1 => (value1.node_admin.product._cls === CLSType.Counter && value1.node_admin.product._ref.$id === cs.id) && value1.node_admin.end_date === null)
        if (cs1Na != null) {
            const newCs1Uid = props.values.items[index].sensor_1_uid
            if (newCs1Uid !== null) {
                const data: keyable = {
                    product_uid: newCs1Uid
                }
                await props.putMutation.mutateAsync({
                    id: cs1Na.node_admin.id,
                    data: data
                })
            }
        }

        // Check second cs
        if (props.countingSetup.counter_node_admins.length > 1) {
            const cs2Na = props.countingSetup.counter_node_admins.find(value1 => (value1.node_admin.product._cls === CLSType.Counter && value1.node_admin.product._ref.$id === cs.id) && value1.node_admin.end_date === null && (value1.node_admin.position.length === 3 && value1.node_admin.position[1] !== 0))
            if (cs2Na != null) {
                const newCs2Uid = props.values.items[index].sensor_2_uid
                const newDistance = props.values.items[index].distance
                const data: keyable = {}
                if (newCs2Uid !== null) {
                    data.product_uid = newCs2Uid
                }
                if (newDistance !== null) {
                    data.distance = newDistance
                }
                if (Object.keys(data).length > 0) {
                    await props.putMutation.mutateAsync({
                        id: cs2Na.node_admin.id,
                        data: data
                    })
                }
            }
        }

        await props.refetch()
            .then(() => props.resetForm())
            .catch(() => props.resetForm())
    }

    const onDeleteClick = async (index: number) => {
        const valid = await validateUIDs()
        if (!valid) return;

        const cs = props.countingSetup.counters[index]

        // First check gateway
        const gwNa = props.countingSetup.gateway_node_admins.find(value1 => (value1.node_admin.product._cls === CLSType.Gateway && value1.node_admin.product._ref.$id === cs.gateway) && value1.node_admin.end_date === null)
        if (gwNa != null) {
            await props.deleteMutation.mutateAsync(gwNa.node_admin.id)
        }

        // Check first cs
        const cs1Na = props.countingSetup.counter_node_admins.find(value1 => (value1.node_admin.product._cls === CLSType.Counter && value1.node_admin.product._ref.$id === cs.id) && value1.node_admin.end_date === null)
        if (cs1Na != null) {
            await props.deleteMutation.mutateAsync(cs1Na.node_admin.id)
        }

        // Check second cs
        if (props.countingSetup.counter_node_admins.length > 1) {
            const cs2Na = props.countingSetup.counter_node_admins.find(value1 => (value1.node_admin.product._cls === CLSType.Counter && value1.node_admin.product._ref.$id === cs.id) && value1.node_admin.end_date === null && (value1.node_admin.position.length === 3 && value1.node_admin.position[1] !== 0))
            if (cs2Na != null) {
                await props.deleteMutation.mutateAsync(cs2Na.node_admin.id)
            }
        }

        props.history.goBack()
    }

    return(
        <form className={"mt-5"} onSubmit={props.handleSubmit}>
            {
                props.status != null
                &&
                <div className={"shadow-none my-3 p-2 bg-green-300 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"} role="alert">
                    <span className={"flex rounded-full bg-green-600 text-white uppercase px-2 py-1 text-xs font-bold mr-3"}>Status</span>
                    <span className={"font-semibold text-white mr-2 text-left flex-auto"}>{props.status}</span>
                </div>
            }
            {
                props.countingSetup.counters.map((value, index) => (
                    <Item key={generateID({page: 'counting-setup-install-item', slug: value.id, index: index})}>
                        <h1>Rijstrook {index+1}<br/>Richting: {value.count_direction === 1 ? props.countingSetup.name_positive : props.countingSetup.name_negative}</h1>
                        <small>Aangepast op: {dayjs(value.updated_at).format('L LT')}</small>
                        <div className={"mt-3"}>
                            <FormGroup>
                                <FormInline>
                                    <FormInlineColl>
                                        <FormLabel>
                                            Gateway node UID:
                                            <SelectMultiple onClick={() => onGatewayClick(index)}/>
                                        </FormLabel>
                                        <ErrorWrapper>
                                            {
                                                // @ts-ignore
                                                props.errors.items != null && props.errors.items.length > index && props.errors.items[index]?.gateway_uid && props.touched.items != null && props.touched.items.length > index && props.touched.items[index]?.gateway_uid && <ErrorItem>{props.errors.items[index]?.gateway_uid}</ErrorItem>
                                            }
                                        </ErrorWrapper>
                                    </FormInlineColl>
                                    <FormInlineColl>
                                        <FormInput onChange={props.handleChange} onBlur={props.handleBlur} type={"number"} name={`items[${index}].gateway_uid`} id={`items.${index}.gateway_uid`} placeholder={"Gateway UID"} value={props.values.items[index]?.gateway_uid ?? ""}/>
                                    </FormInlineColl>
                                </FormInline>
                            </FormGroup>
                            <FormGroup>
                                <FormInline>
                                    <FormInlineColl>
                                        <FormLabel>
                                            Sensor 1 node UID:
                                            <SelectMultiple onClick={() => onSensorClick(index, 1)}/>
                                        </FormLabel>
                                        <ErrorWrapper>
                                            {
                                                // @ts-ignore
                                                props.errors.items != null && props.errors.items.length > index && props.errors.items[index]?.sensor_1_uid && props.touched.items != null && props.touched.items.length > index && props.touched.items[index]?.sensor_1_uid && <ErrorItem>{props.errors.items[index]?.sensor_1_uid}</ErrorItem>
                                            }
                                        </ErrorWrapper>
                                    </FormInlineColl>
                                    <FormInlineColl>
                                        <FormInput onChange={props.handleChange} onBlur={props.handleBlur} type={"number"} name={`items[${index}].sensor_1_uid`} id={`items.${index}.sensor_1_uid`} placeholder={"Sensor 1 UID"} value={props.values.items[index]?.sensor_1_uid ?? ""}/>
                                    </FormInlineColl>
                                </FormInline>
                            </FormGroup>
                            <FormGroup>
                                <FormInline>
                                    <FormInlineColl>
                                        <FormLabel>
                                            Tussen afstand:
                                        </FormLabel>
                                        <ErrorWrapper>
                                            {
                                                // @ts-ignore
                                                props.errors.items != null && props.errors.items.length > index && props.errors.items[index]?.distance && props.touched.items != null && props.touched.items.length > index && props.touched.items[index]?.distance && <ErrorItem>{props.errors.items[index]?.distance}</ErrorItem>
                                            }
                                        </ErrorWrapper>
                                    </FormInlineColl>
                                    <FormInlineColl>
                                        <FormInput onChange={props.handleChange} onBlur={props.handleBlur} type={"number"} name={`items[${index}].distance`} id={`items.${index}.distance`} placeholder={""} value={props.values.items[index]?.distance ?? ""}/>
                                        <p className={"ml-2"}>meter</p>
                                    </FormInlineColl>
                                </FormInline>
                            </FormGroup>
                            <FormGroup>
                                <FormInline>
                                    <FormInlineColl>
                                        <FormLabel>
                                            Sensor 2 node UID:
                                            <SelectMultiple onClick={() => onSensorClick(index, 2)}/>
                                        </FormLabel>
                                        <ErrorWrapper>
                                            {
                                                // @ts-ignore
                                                props.errors.items != null && props.errors.items.length > index && props.errors.items[index]?.sensor_2_uid && props.touched.items != null && props.touched.items.length > index && props.touched.items[index]?.sensor_2_uid && <ErrorItem>{props.errors.items[index]?.sensor_2_uid}</ErrorItem>
                                            }
                                        </ErrorWrapper>
                                    </FormInlineColl>
                                    <FormInlineColl>
                                        <FormInput onChange={props.handleChange} onBlur={props.handleBlur} type={"number"} name={`items[${index}].sensor_2_uid`} id={`items.${index}.sensor_2_uid`} placeholder={"Sensor 2 UID"} value={props.values.items[index]?.sensor_2_uid ?? ""}/>
                                    </FormInlineColl>
                                </FormInline>
                            </FormGroup>
                        </div>
                        <FormGroup>
                            <div className={"flex w-full"}>
                                <div className={"w-1/2 pr-2 flex justify-between"}>
                                    <FormButton type={"button"} onClick={() => onUpdateClick(index)}>
                                        Bijwerken
                                    </FormButton>
                                    <FormCheckbox onChange={props.handleChange} onBlur={props.handleBlur} type={"checkbox"} name={`items[${index}].buttons_enabled`} checked={props.values.items[index]?.buttons_enabled ?? false}/>
                                </div>
                                <div className={"w-1/2 pl-2 flex"}>
                                    <div className={"mr-2"}>
                                        <FormButton type={"button"} onClick={() => onReplaceClick(index)} disabled={
                                            !props.values.items[index]?.buttons_enabled ?? true
                                        }>
                                            Vervangen
                                        </FormButton>
                                    </div>
                                    <FormCancelButton type={"button"} onClick={() => onDeleteClick(index)} disabled={
                                        !props.values.items[index]?.buttons_enabled ?? true
                                    }>
                                        Verwijderen
                                    </FormCancelButton>
                                </div>
                            </div>
                        </FormGroup>
                    </Item>
                ))
            }
            <div className={"flex w-full"}>
                <FormCancelButton type={"button"} onClick={() => props.history.goBack()}>
                    Cancel
                </FormCancelButton>
            </div>
        </form>
    )
}

const Item = styled.div``

const InstallerCountingSetupsEditInstallPageForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => ({
        items: props.countingSetup.counters.map((value) => ({
            buttons_enabled: false,
            counter_id: value.id,
            gateway_id: value.gateway,
            gateway_uid: props.countingSetup.gateway_node_admins.find(value1 => (value1.node_admin.product._cls === CLSType.Gateway && value1.node_admin.product._ref.$id === value.gateway) && value1.node_admin.end_date === null)?.node.uid ?? null,
            sensor_1_uid: props.countingSetup.counter_node_admins.find(value1 => (value1.node_admin.product._cls === CLSType.Counter && value1.node_admin.product._ref.$id === value.id) && value1.node_admin.end_date === null)?.node.uid ?? null,
            distance: props.countingSetup.counter_node_admins.length > 1 ? props.countingSetup.counter_node_admins.find(value1 => (value1.node_admin.product._cls === CLSType.Counter && value1.node_admin.product._ref.$id === value.id) && value1.node_admin.end_date === null && (value1.node_admin.position.length === 3 && value1.node_admin.position[1] !== 0))?.node_admin.position[1] ?? null : null,
            sensor_2_uid: props.countingSetup.counter_node_admins.length > 1 ? props.countingSetup.counter_node_admins.find(value1 => (value1.node_admin.product._cls === CLSType.Counter && value1.node_admin.product._ref.$id === value.id) && value1.node_admin.end_date === null && (value1.node_admin.position.length === 3 && value1.node_admin.position[1] !== 0))?.node.uid ?? null : null,
        }))
    }),
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
        items: Yup.array().of(
            Yup.object().shape({
                gateway_uid: Yup.number()
                    .typeError("Must be a number")
                    .notRequired()
                    .nullable(),
                sensor_1_uid: Yup.number()
                    .typeError("Must be a number")
                    .required("Sensor 1 UID is required"),
                distance: Yup.number()
                    .typeError("Must be a number")
                    .notRequired()
                    .nullable(),
                sensor_2_uid: Yup.number()
                    .typeError("Must be a number")
                    .notRequired()
                    .nullable()
            })
        )
    }),
    handleSubmit: () => {}
})(withToast(InnerForm))

export default InstallerCountingSetupsEditInstallPageForm
