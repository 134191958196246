import React, {useState} from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import Map, {MapRef, Marker, NavigationControl} from 'react-map-gl'
import env from '../../../production.env'
import mapboxgl from 'mapbox-gl'
import {MultiPointField} from '../../../models/location'
import {MapMarkerAlt} from '@styled-icons/fa-solid'
import {CustomControl} from '../SelectLocation/SelectLocation';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default

const dialogNode = document.getElementById('dialog-node')

interface Props {
    location: MultiPointField
}

export const viewLocation = (
    DialogContent: (props: {
        response: () => void,
        content: Props
    }) => React.ReactElement,
    props: Props
): Promise<void> =>
    new Promise<void>(res => {
        if (dialogNode == null) return
        const giveAnswer = () => {
            ReactDOM.unmountComponentAtNode(dialogNode)
            res()
        }

        ReactDOM.render(<DialogContent response={giveAnswer} content={props}/>, dialogNode)
    })

interface ComponentProps {
    response: () => void
    content: Props
}

export const ViewLocationDialog = ({ response, content }: ComponentProps) => {
    const [satellite, setSatellite] = useState<boolean>(false)
    const ref = React.useRef<MapRef>(null)

    const [state, setState] = useState<any>({
        latitude: content.location.coordinates[0][0] ?? 52.35026259227887,
        longitude: content.location.coordinates[0][1]?? 4.762992649377801,
        zoom: 15,
    })

    const outsideClick = () => response()

    const renderTools = () => (
        <>
            <NavigationControl style={{ bottom: "110px", left: "10px" }}/>
            <CustomControl satellite={satellite} setSatellite={setSatellite} setLocation={setState}/>
        </>
    )

    return(
        <Modal>
            <ModalBg onClick={outsideClick}/>
            <ModalContent>
                <Map
                    id="viewLocationMap"
                    ref={ref}
                    mapboxAccessToken={env.mapBoxToken}
                    mapStyle={satellite ? 'mapbox://styles/mapbox/satellite-streets-v11' : 'mapbox://styles/parkeagle/ck42k79xi02kf1cmyljveiqcc'}
                    {...state}
                    onMove={(evt: { viewState: any }) => setState(evt.viewState)}
                    style={{width: '100%', height: '100%'}}
                >
                    <Marker longitude={content.location.coordinates[0][1]} latitude={content.location.coordinates[0][0]} anchor='bottom'>
                        <Icon/>
                    </Marker>
                    {renderTools()}
                </Map>
            </ModalContent>
        </Modal>
    )
}

const Modal = styled.div`
  position: fixed;
  z-index: 10010;
`

const ModalBg = styled.div`
  background: rgba(70, 75, 79, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
`

const ModalContent = styled.div`
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px;
  pointer-events: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 1000px;
  height: 750px;
`

const Icon = styled(MapMarkerAlt)`
  color: #1482ff;
  width: 20px;
  height: auto;
`
