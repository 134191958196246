import React from 'react'
import ActionLink from '../../../components/buttons/action/ActionLink'
import {AddToQueue} from '@styled-icons/boxicons-regular'
import HeaderComponent from '../../../components/header/Header'
import ContentComponent from '../../../components/content/Content'
import SpacesList from '../../../components/spaces/SpacesList/SpacesList'
import SearchList from '../../../components/search/SearchList';

const SpacesPage = () => {
    const [name, setName] = React.useState<string>('')

    return (
        <>
            <HeaderComponent title={"Spaces"} subtitleComponent={<SearchList value={name} action={setName}/>}>
                <ActionLink to={`/spaces/new`} icon={<AddToQueue/>}>
                    New
                </ActionLink>
            </HeaderComponent>
            <ContentComponent>
                <SpacesList title={false} name={name}/>
            </ContentComponent>
        </>
    )
}

export default SpacesPage
