import React from 'react'
import {GenericLazyReferenceField} from '../../../models/base'
import {UseMutationResult} from 'react-query'
import {GetNode} from '../../../models/node'
import {CreateNodeInput, NodeType} from '../../../hooks/nodes'
import { History } from 'history'
import {Space} from '../../../models/space'
import {FormikProps, withFormik} from 'formik'
import * as Yup from 'yup'
import {
    ErrorItem,
    ErrorWrapper,
    FormButton,
    FormGroup,
    FormInput,
    FormLabel, FormSelect,
    FormTextarea
} from '../../../styles/shared'
import generateID from '../../../helpers/id'
import {AxiosResponse} from 'axios'

interface FormValues {
    description: string
    uid: string
    node_type: string
    node_admins: FormValuesNodeAdmin[]
}

interface FormValuesNodeAdmin {
    space: string
    product: GenericLazyReferenceField|null
    position: number[]
    orientation: number[]
}

interface FormProps {
    mutation: UseMutationResult<AxiosResponse<GetNode>, Error, CreateNodeInput, unknown>
    history: History
    spaces: Space[]
}

const innerForm: React.FC<FormProps & FormikProps<FormValues>> = (props) => {
    return(
        <form className={"mt-5"} onSubmit={props.handleSubmit}>
            {
                props.status != null
                &&
                <div className={"shadow-none my-3 p-2 bg-green-300 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"} role="alert">
                    <span className={"flex rounded-full bg-green-600 text-white uppercase px-2 py-1 text-xs font-bold mr-3"}>Status</span>
                    <span className={"font-semibold text-white mr-2 text-left flex-auto"}>{props.status}</span>
                </div>
            }
            <FormGroup>
                <FormLabel htmlFor={"uid"}>Uid</FormLabel>
                <FormInput onChange={props.handleChange} onBlur={props.handleBlur} type={"string"} name={"uid"} id={"uid"} value={props.values.uid ?? ""}/>
                <ErrorWrapper>
                    {
                        props.errors.uid && props.touched.uid && <ErrorItem>{props.errors.uid}</ErrorItem>
                    }
                </ErrorWrapper>
            </FormGroup>
            <FormGroup>
                <FormLabel htmlFor={"description"}>Description</FormLabel>
                <FormTextarea onChange={props.handleChange} onBlur={props.handleBlur} name={"description"} id={"description"} value={props.values.description}/>
                <ErrorWrapper>
                    {
                        props.errors.description && props.touched.description && <ErrorItem>{props.errors.description}</ErrorItem>
                    }
                </ErrorWrapper>
            </FormGroup>
            <FormGroup>
                <FormLabel htmlFor={"node_type"}>Node Type</FormLabel>
                <FormSelect onChange={props.handleChange} onBlur={props.handleBlur} name={"node_type"} id={"node_type"} value={props.values.node_type}>
                    {
                        Object.values(NodeType).map((value, index) => (
                            <option key={generateID({page: "nodes-new-node-type", slug: value, index: index})} value={value}>{value}</option>
                        ))
                    }
                    <option value={""} disabled hidden>Select a node type</option>
                </FormSelect>
                <ErrorWrapper>
                    {
                        props.errors.node_type && props.touched.node_type && <ErrorItem>{props.errors.node_type}</ErrorItem>
                    }
                </ErrorWrapper>
            </FormGroup>
            <FormButton type={"submit"} disabled={
                props.isSubmitting ||
                !!(props.errors.node_type && props.touched.node_type)
            }>
                Submit
            </FormButton>
        </form>
    )
}

const NodesNewPageForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => ({
        description: "",
        uid: "",
        node_type: "",
        node_admins: []
    }),
    validationSchema: Yup.object().shape({
        description: Yup.string()
            .notRequired(),
        node_type: Yup.string()
            .required("Name is required"),
        uid: Yup.string()
            .required("Uid is required"),
    }),
    handleSubmit: (values, {props, setSubmitting, setStatus}) => {
        setStatus(undefined)
        if (values.uid === null) return
        values.uid.split(",").forEach(async (uid) => {
            const parsed = parseInt(uid)
            if (isNaN(parsed)) return
            await props.mutation.mutateAsync({
                display_name: `Node ${uid}`,
                description: values.description,
                uid: parsed,
                node_type: values.node_type,
                node_admins: []
            })
        })
        setStatus("Successfully created the nodes")
        setSubmitting(false)
        setTimeout(() => {
            props.history.push(`/nodes`)
        }, 2000)
    }
})(innerForm)

export default NodesNewPageForm
