import React, {useState} from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import Map, {NavigationControl, MapRef, Marker} from 'react-map-gl'
import env from '../../../production.env'
import mapboxgl, {LngLat} from 'mapbox-gl'
import { CurrentLocation } from '@styled-icons/boxicons-regular'
import { Layers } from '@styled-icons/bootstrap'
import {Search} from '@styled-icons/boxicons-regular'
import {searchLocation, SearchLocationDialog} from '../SearchLocation/SearchLocation'
import axios from "axios";
import {isUndefined} from "../../../helpers/helpers";

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default

const dialogNode = document.getElementById('dialog-node')

interface Props {
    location?: Location
    initialLocation?: Location
}

interface Location {
    latitude: number
    longitude: number
}

interface LocationResponse {
    name: string|null
    location: Location
}


interface PointerEventMapBox {
    point: any
    lngLat: LngLat
    originalEvent: any
    type: string
    features?: any // Only in older version of mapbox, should replace this everywhere
}

export const selectLocation = (
    DialogContent: (props: {
        response: (answer: LocationResponse|null) => void,
        content: Props
    }) => React.ReactElement,
    props: Props
): Promise<LocationResponse|null> =>
    new Promise<LocationResponse|null>(res => {
        if (dialogNode == null) return
        const giveAnswer = (answer: LocationResponse|null) => {
            ReactDOM.unmountComponentAtNode(dialogNode)
            res(answer)
        }

        ReactDOM.render(<DialogContent response={giveAnswer} content={props}/>, dialogNode)
    })

interface ComponentProps {
    response: (answer: LocationResponse|null) => void
    content: Props
}

interface SearchLocationProps {
    setMarker: React.Dispatch<React.SetStateAction<JSX.Element|null>>
    response: (answer: LocationResponse|null) => void
    setLocation: React.Dispatch<React.SetStateAction<any>>
}

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

const SearchLocation = ({ setMarker, response, setLocation }: SearchLocationProps) => {
    const onClick = async () => {
        await searchLocation(SearchLocationDialog, {})
            .then(res => {
                if (res !== null) {
                    setMarker(<Marker longitude={res.location.longitude} latitude={res.location.latitude} key={Math.random()} anchor="bottom">
                        <svg
                            height={20}
                            viewBox="0 0 24 24"
                            style={{
                                cursor: 'pointer',
                                fill: '#1482FF',
                                stroke: 'none',
                                transform: `translate(${-20 / 2}px,${-20}px)`
                            }}
                            onClick={() => response(res)}
                        >
                            <path d={ICON} />
                        </svg>
                    </Marker>)
                    setLocation((prevState: any) => ({
                        ...prevState,
                        latitude: res.location.latitude,
                        longitude: res.location.longitude,
                        zoom: 16
                    }))
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    return(
        <div style={{ position: "absolute", bottom: "40px", right: "10px" }}>
            <div>
                <SearchLocationButton type={"button"} onClick={onClick}>
                    <Search/>
                </SearchLocationButton>
            </div>
        </div>
    )
}

const SearchLocationButton = styled.button`
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: #1482FF;
  transition: .2s background-color;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  box-shadow: 0 6px 20px rgba(0,0,0,0.2);
  font-size: 20px;
  cursor: pointer;
  
  &:hover {
    background-color: #07f;
  }
  
  & > svg {
    width: 30px;
    height: auto;
  }
`

interface CustomControlProps {
    satellite: boolean
    setSatellite: React.Dispatch<React.SetStateAction<boolean>>
    setLocation: React.Dispatch<React.SetStateAction<any>>
}

export const CustomControl = ({ satellite, setSatellite, setLocation }: CustomControlProps) => {
    const currentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    setLocation((prevState: any) => ({
                        ...prevState,
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        zoom: 13
                    }))
                },
                err => {
                    console.log(err)
                },
            )
        }
    }

    return(
        <div style={{ position: "absolute", bottom: "40px", left: "10px" }}>
            <div className={"mapboxgl-ctrl-group mapboxgl-ctrl"}>
                <button onClick={() => setSatellite(!satellite)} style={{ display: "flex", alignItems: "center", justifyContent: "center" }} className={`mapboxgl-ctrl-icon`} type={"button"} title={satellite ? "Default View" : "Satellite View"}>
                    <Layers width={18}/>
                </button>
                <button onClick={() => currentLocation()} style={{ display: "flex", alignItems: "center", justifyContent: "center" }} className={`mapboxgl-ctrl-icon`} type={"button"} title={"Current Location"}>
                    <CurrentLocation width={18}/>
                </button>
            </div>
        </div>
    )
}

export const SelectLocationDialog = ({ response, content }: ComponentProps) => {
    const [marker, setMarker] = useState<JSX.Element|null>(null)
    const [satellite, setSatellite] = useState<boolean>(false)
    const ref = React.useRef<MapRef>(null)

    const [state, setState] = useState<any>({
        latitude: content.location?.latitude ?? content.initialLocation?.latitude ?? 52.35026259227887,
        longitude: content.location?.longitude ??  content.initialLocation?.longitude ?? 4.762992649377801,
        zoom: (content.location != null || content.initialLocation != null) ? 15 : 8.361744731470317,
    })

    const requestLocation = async (latitude: number, longitude: number) => {
        return await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${env.mapBoxToken}&country=nl,be,de`).then(res => res.data).catch(err => err.response.data)
    }

    const outsideClick = () => response(null)

    const onClick = async (e: PointerEventMapBox) => {
        // console.log('Click map event:')
        // console.log(e)
        const locationFeatures = await requestLocation(e.lngLat.lat, e.lngLat.lng)
        console.log(locationFeatures)
        response({
            location: {latitude: e.lngLat.lat, longitude: e.lngLat.lng},
            name: (!isUndefined(locationFeatures) && 'features' in locationFeatures && locationFeatures.features.length > 0 && !isUndefined(locationFeatures.features[0].place_name)) ? locationFeatures.features[0].place_name.split(',')[0] || "" : ""
        })
    }

    const renderTools = () => (
        <>
            <NavigationControl style={{ bottom: "110px", left: "10px" }}/>
            <CustomControl satellite={satellite} setSatellite={setSatellite} setLocation={setState}/>
            <SearchLocation setMarker={setMarker} response={response} setLocation={setState}/>
        </>
    )

    return(
        <Modal>
            <ModalBg onClick={outsideClick}/>
            <ModalContent>
                {/*// @ts-ignore*/}
                <Map
                    id="selectLocationMap"
                    ref={ref}
                    mapboxAccessToken={env.mapBoxToken}
                    onClick={onClick}
                    mapStyle={satellite ? 'mapbox://styles/mapbox/satellite-streets-v11' : 'mapbox://styles/parkeagle/ck42k79xi02kf1cmyljveiqcc'}
                    {...state}
                    style={{width: '100%', height: '100%'}}
                    onMove={(evt: { viewState: any }) => setState(evt.viewState)}>
                    {renderTools()}
                    {marker && marker}
                </Map>
            </ModalContent>
        </Modal>
    )
}

const Modal = styled.div`
  position: fixed;
  z-index: 10010;
`

const ModalBg = styled.div`
  background: rgba(70, 75, 79, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
`

const ModalContent = styled.div`
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px;
  pointer-events: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 1000px;
  height: 750px;
`
