import React from 'react'
import {Counter} from '../../../models/products'
import styled from 'styled-components'
import {ActionTd, EditTh, SubHeader, Table, Tbody, Td, Th, Thead} from '../../../styles/shared'
import generateID from '../../../helpers/id'
import dayjs from 'dayjs'
import {Link} from 'react-router-dom'
import ShadowTable from '../../tables/ShadowTable/ShadowTable'

interface Props {
    counters: Counter[]
    name_positive: string|null
    name_negative: string|null
    showActions?: boolean
}

const CounterList = ({ counters, name_positive=null, name_negative=null, showActions=false }: Props) => (
    <Wrapper>
        <div className={"mb-3"}>
            <SubHeader>Counters</SubHeader>
        </div>
        <ShadowTable>
            <Table>
                <Thead>
                    <tr>
                        <Th scope={"col"}>Name</Th>
                        <Th scope={"col"}>Created</Th>
                        <Th scope={"col"}>Updated</Th>
                        <Th scope={"col"}>Direction</Th>
                        {
                            showActions
                            &&
                            <EditTh scope={"col"} className={"relative px-6 py-3"}>
                                <span className={"sr-only"}>View</span>
                            </EditTh>
                        }
                    </tr>
                </Thead>
                <Tbody>
                    {
                        counters.sort((a, b) => {
                            if (typeof a.display_name === 'string' && typeof b.display_name === 'string'){
                                return a.display_name.localeCompare(b.display_name)
                            }
                            return 0
                        }).map((value, index) => (
                            <tr key={generateID({page: 'counting-setup-counter-item', slug: value.id, index: index})}>
                                <Td>{value.display_name}</Td>
                                <Td>{dayjs(value.created_at).fromNow()}</Td>
                                <Td>{dayjs(value.updated_at).fromNow()}</Td>
                                <Td>{value.count_direction === -1 ? name_negative : ''}{value.count_direction === 1 ? name_positive : ''}</Td>
                                {
                                    showActions
                                    &&
                                    <ActionTd>
                                        <Link to={`/counters/view/${value.id}`} className={"text-blue-500 hover:text-blue-700"}>View</Link>
                                    </ActionTd>
                                }
                            </tr>
                        ))
                    }
                </Tbody>
            </Table>
        </ShadowTable>
    </Wrapper>
)

const Wrapper = styled.div.attrs({
    className: "flex flex-col mt-3"
})``

export default CounterList
